.sidebar {
  flex: 0 0 45.8rem;
  padding: 5.6rem 4.8rem;
  min-height: 100vh;
  background: var(--color-secondary-light-3);
}

.title {
  margin-top: 13rem;
}

.list {
  margin-top: 4.4rem;
}

.logo{
  width: 3.6rem;
  height: 4.8rem;
}