@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

$pagination-text-color: var(--color-text);
$pagination-item-bg: var(--color-white);
$pagination-item-text-color: var(--color-text);
$pagination-item-focus-text-color: var(--color-primary-dark-1);
$pagination-item-active-bg-color: var(--color-primary-dark-1);
$pagination-item-active-text-color: var(--color-white);
$pagination-item-active-hover-bg-color: var(--color-primary-dark-4);
$pagination-disabled-item-bg: var(--color-additional-disabled);
$pagination-disabled-item-color: var(--color-main-disabled);
$pagination-disabled-item-active-bg: var(--color-secondary-light-2);
$pagination-disabled-item-active-color: var(--color-main-disabled);
$pagination-jump-ellipsis-color: var(--color-border);
$pagination-jump-icon-color: var(--color-primary-dark-1);
$pagination-arrow-bg: var(--color-white);
$pagination-arrow-color: var(--color-primary-dark-1);
$pagination-arrow-border-color: var(--color-border);
$pagination-arrow-hover-color: var(--color-primary-dark-1);
$pagination-arrow-hover-border-color: var(--color-primary-dark-1);
$pagination-disabled-arrow-color: var(--color-main-disabled);
$pagination-disabled-arrow-border-color: var(--color-main-disabled);
$pagination-disabled-arrow-bg: var(--color-additional-disabled);
$pagination-simple-input-border-color: var(--color-border);
$pagination-simple-input-hover-border-color: var(--color-primary-dark-1);
$pagination-simple-input-bg: var(--color-white);
$pagination-simple-input-focus-border-color: var(--color-primary-dark-1);
$pagination-disabled-simple-text-color: var(--color-main-disabled);
$pagination-disabled-simple-input-color: var(--color-main-disabled);
$pagination-disabled-simple-bg: var(--color-additional-disabled);
$pagination-jumper-text-color: var(--color-text);
$pagination-jumper-bg: var(--color-white);
$pagination-jumper-border-color: var(--color-border);
$pagination-jumper-hover-border-color: var(--color-primary-dark-1);
$pagination-disabled-jumper-text-color: var(--color-main-disabled);
$pagination-disabled-jumper-bg: var(--color-additional-disabled);
$pagination-disabled-jumper-border-color: var(--color-main-disabled);

$pagination-item-border-radius: variables.$border-radius-md;
$pagination-arrow-border-radius: variables.$border-radius-md;
$pagination-simple-border-radius: variables.$border-radius-md;

$flex-order-left: 1;
$flex-order-center: 2;
$flex-order-right: 3;
$pagination-item-size: 4rem;

@mixin paginationItemSize {
  min-width: $pagination-item-size;
  height: $pagination-item-size;
}

.ant-pagination {
  color: $pagination-text-color;
  height: $pagination-item-size;
  @include mixins.font-size-16;

  &.ant-table-pagination {
    flex-wrap: nowrap;
  }

  .ant-pagination-total-text {
    height: $pagination-item-size;
    line-height: $pagination-item-size;
    order: $flex-order-right;
    margin-right: unset;
    margin-left: auto;
    text-align: right;
  }

  .ant-pagination-jump-next, .ant-pagination-jump-prev {
    @include paginationItemSize;
    line-height: 4rem;
    order: $flex-order-center;
  }
}

.ant-pagination-item {
  @include paginationItemSize;
  line-height: 4rem;
  background-color: $pagination-item-bg;
  border-radius: $pagination-item-border-radius;
  border: unset;
  order: $flex-order-center;

  a {
    @include paginationItemSize;
    color: $pagination-item-text-color;
  }
  &:focus-visible a, &:hover a {
    color: $pagination-item-focus-text-color;
  }
}

.ant-pagination-item-active {
  background-color: $pagination-item-active-bg-color;

  &:hover {
    background-color: $pagination-item-active-hover-bg-color;
  }
  a {
    color: $pagination-item-active-text-color;
  }
  &:focus-visible a, &:hover a {
    color: $pagination-item-active-text-color;
  }
}

.ant-pagination-next, .ant-pagination-prev {
  height: $pagination-item-size;
  order: $flex-order-center;
  button {
    color: $pagination-arrow-color;
  }
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-color: $pagination-arrow-border-color;
  background: $pagination-arrow-bg;
  border-radius: $pagination-arrow-border-radius;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  color: $pagination-arrow-hover-color;
  border-color: $pagination-arrow-hover-border-color;
}

.ant-pagination-simple .ant-pagination-simple-pager,
.ant-pagination-simple .ant-pagination-next,
.ant-pagination-simple .ant-pagination-prev {
  height: 3.2rem;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: $pagination-simple-input-bg;
  border-color: $pagination-simple-input-border-color;
  border-radius: $pagination-simple-border-radius;

  &:hover {
    border-color: $pagination-simple-input-hover-border-color;
  }
  &:focus {
    border-color: $pagination-simple-input-focus-border-color;
    box-shadow: none;
  }
  &[disabled] {
    color: $pagination-disabled-simple-input-color;
    background: $pagination-disabled-simple-bg;
    border-color: $pagination-disabled-simple-bg;
  }
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: $pagination-disabled-item-bg;

  a {
    color: $pagination-disabled-item-color;
  }
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: $pagination-disabled-item-active-bg;

  a {
    color: $pagination-disabled-item-active-color;
  }
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: $pagination-jump-ellipsis-color;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $pagination-jump-icon-color;
}

.ant-pagination-options {
  width: 20rem;
  order: $flex-order-left;
  margin-left: unset;
  margin-right: auto;

  .ant-pagination-options-size-changer.ant-select {
    width: 100%;
  }
}

.ant-pagination-options-quick-jumper {
  input {
    color: $pagination-jumper-text-color;
    background-color: $pagination-jumper-bg;
    border-color: $pagination-jumper-border-color;
    &:focus, &:hover {
      border-color: $pagination-jumper-hover-border-color;
      box-shadow: none;
    }
    &[disabled] {
      color: $pagination-disabled-jumper-text-color;
      background-color: $pagination-disabled-jumper-bg;
      border-color: $pagination-disabled-jumper-bg;
    }
  }
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  border-color: $pagination-disabled-arrow-border-color;
  background: $pagination-disabled-arrow-bg;
  color: $pagination-disabled-arrow-color;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: $pagination-disabled-simple-text-color;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: $pagination-disabled-arrow-color;
  border-color: $pagination-disabled-arrow-border-color;
}