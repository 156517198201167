$editor-width: 29.7cm;
$editor-padding-top: 2.5cm;
$editor-padding-right: 1.5cm;
$editor-padding-bottom: 1cm;
$editor-padding-left: 1.5cm;

$table-width: calc($editor-width - $editor-padding-right - $editor-padding-left);

$contour-line-width: 2rem;
$contour-line-height: 1px;
$contour-z-index: 10;

$control-panel-offset-x: 0.4rem;
$control-panel-z-index: 11;
$control-panel-row-actions-z-index: 12;

:export {
  editorWidth: $editor-width;
  tableWidth: $table-width;
}