@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";

$table-constructor-border-color: var(--color-text);
$table-constructor-border-radius: vars.$border-radius-lg;
$table-constructor-border: 1px solid $table-constructor-border-color;

.table-constructor-outer-container {
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
}

.table-constructor-inner-container {
  position: relative;
}

.table-constructor {
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: vars.$secondary-font-family;

  * {
    font-family: inherit;
  }
}

.table-constructor-thead {
  box-shadow: -1px 0px 0px $table-constructor-border-color;
  border-top-left-radius: $table-constructor-border-radius;

  tr {
    vertical-align: top;

    th {
      padding: 0.4rem;
      text-align: center;
      font-weight: 400;
      @include mixins.font-size-14();
      border-right: $table-constructor-border;
      border-bottom: $table-constructor-border;
      white-space: break-spaces;
      word-break: break-word;
    }

    &:first-child {
      th {
        border-top: $table-constructor-border;
      }
    }
  }
}

.table-constructor-tbody {
  box-shadow: -1px 0px 0px $table-constructor-border-color;
  border-bottom-left-radius: $table-constructor-border-radius;

  tr {
    vertical-align: top;

    td {
      padding: 0.5rem;
      border-right: $table-constructor-border;
      border-bottom: $table-constructor-border;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}

.table-constructor-cell {
  position: relative;

  &_hidden {
    display: none;
  }

  &_editable {
    cursor: pointer;
  }

  &_first-row-first-visible {
    border-top-left-radius: $table-constructor-border-radius;
  }

  &_first-row-last-visible {
    border-top-right-radius: $table-constructor-border-radius;
  }

  &_last-row-first-visible {
    border-bottom-left-radius: $table-constructor-border-radius;
  }

  &_last-row-last-visible {
    border-bottom-right-radius: $table-constructor-border-radius;
  }
}