$sm: 768px;
$md: 1024px;
$lg: 1200px;

$border-radius-xl: 1rem;
$border-radius-lg: .8rem;
$border-radius-md: .4rem;
$border-radius-sm: .2rem;

$padding-xxxl: 4.8rem;
$padding-xxl: 3.2rem;
$padding-xl: 2.4rem;
$padding-lg: 2rem;
$padding-md: 1.6rem;
$padding-sm: 1.2rem;
$padding-xs: 0.8rem;
$padding-xxs: 0.4rem;
$padding-xxxs: 0.2rem;

$margin-xxl: 3.2rem;
$margin-xl: 2.4rem;
$margin-lg: 2rem;
$margin-md: 1.6rem;
$margin-sm: 1.2rem;
$margin-xs: 0.8rem;
$margin-xxs: 0.4rem;
$margin-xxxs: 0.2rem;

$z-index-max: 2147483647;
$z-index-block: 11000;
$z-index-dropdown: 10000;
$z-index-popover: 9000;
$z-index-header: 8000;
$z-index-sider: 1000;
$z-index-modal: 1000;
$z-index-mobile-menu-close: 1000;
$z-index-radio-button-checked: 2;

$primary-font-family: "Inter", sans-serif;
$secondary-font-family: "Times New Roman", sans-serif;