@mixin box-shadow-light-2 {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1) !important;
}

@mixin box-shadow-light-1 {
  box-shadow: 0 4px 10px rgba(0, 0, 0, .06) !important;
}

@mixin box-shadow {
  box-shadow: 1px 1px 5px rgba(0,0,0,.1) !important;
}

@mixin distant-box-shadow {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
              0 6px 16px 0 rgba(0, 0, 0, 0.08),
              0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

@mixin popup-box-shadow {
  box-shadow: 0px 2px 20px rgba(221, 221, 221, 0.5);
}