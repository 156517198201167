@use "src/shared/UI/styles/variables/layout";

$img-size: 10rem;
$bg-size: 5rem;
$bg-color:var(--color-secondary-light-4);
$bg-image: url("../../../../assets/images/img-skeleton.svg");

.skeleton-img{
  width: $img-size;
  height: $img-size;
  border-radius: layout.$border-radius-sm;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: $bg-size;
  background-image: $bg-image;
  background-color: $bg-color;
}