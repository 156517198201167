@use "src/shared/UI/styles/variables" as vars;

.wrapper {
  width: 29.7cm;
  padding: 2.5cm 1.5cm 1cm 1.5cm;
  background: var(--color-white);
}

.title {
  margin-bottom: vars.$margin-xl !important;
}

.table {
  width: 100%;
  border: 1px solid var(--color-black);

  & th, & td {
    border: 1px solid var(--color-black);
  }
}