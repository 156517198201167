$form-item-width-unit: 9rem;
$form-item-margin-right: 1rem;

$sizes: (
  "xxl": calc(#{$form-item-width-unit} * 6 + #{$form-item-margin-right} * 5),
  "xl": calc(#{$form-item-width-unit} * 5 + #{$form-item-margin-right} * 4),
  "lg": calc(#{$form-item-width-unit} * 4 + #{$form-item-margin-right} * 3),
  "md": calc(#{$form-item-width-unit} * 3 + #{$form-item-margin-right} * 2),
  "sm": calc(#{$form-item-width-unit} * 2 + #{$form-item-margin-right}),
  "xs": #{$form-item-width-unit},
);

@each $size, $sizeValue in $sizes {
  .item-#{$size} {
    width: $sizeValue;
  }
}

.label {
  &-black :global label  {
    color: var(--color-black);
  }

  &-gray :global label {
    color: var(--color-secondary-dark-1);
    font-weight: 400;
  }
}
