@use "src/shared/UI/styles/variables" as vars;

.view-settings-options-item {
  margin-top: vars.$margin-lg;
}

.settings-options-item {
  margin-top: vars.$margin-lg;
}

.columns-options-item {
  margin-top: vars.$margin-lg;
}