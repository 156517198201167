@use "../../LinkBase.module";

.link {
  &.isActive {
    color: var(--color-primary) !important;

    & :global(.ant-typography) {
      color: var(--color-primary) !important;
    }
  }
}