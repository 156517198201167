@use "src/shared/UI/styles/variables" as vars;

$table-top-pagination-margin: 0 0 vars.$margin-md 0;
$table-bottom-pagination-margin: vars.$margin-md 0 0 0;

.ant-pagination.ant-table-pagination {
  &:first-child {
    margin: $table-top-pagination-margin;
  }

  &:last-child {
    margin: $table-bottom-pagination-margin;
  }
}