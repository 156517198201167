@use "src/shared/UI/styles/variables";
@use "./sizes.module";

$button-border-radius: variables.$border-radius-md;

.button {
  &, &.fixed-radius {
    border-radius: $button-border-radius;
  }
  &.shape-circle {
    border-radius: 50%;
  }
  &.shape-round {
    border-radius: 1000rem;
  }
}