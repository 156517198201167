@use "../base.module";

.divider {
  border-top: 0;
  width: 100%;
  margin: 0;
  align-self: center;

  @include base.divider-borders;
  @include base.divider-width;

  span {
    display: inline-block;
    padding: 0 1em;
  }

  &::before {
    position: relative;
    top: 50%;
    content: "";
    border-top: solid base.$divider-color;
    border-bottom: 0;
    transform: translateY(50%);
  }
  &::after {
    position: relative;
    top: 50%;
    content: "";
    border-top: solid base.$divider-color;
    border-bottom: 0;
    transform: translateY(50%);
  }

  &--center {
    &::before {
      width: 50%;
    }
    &::after {
      width:  50%;
    }
  }

  &--right {
    &::before {
      width: 85%;
    }
    &::after {
      width:  15%;
    }
  }

  &--left {
    &::before {
      width: 15%;
    }
    &::after {
      width:  85%;
    }
  }
}