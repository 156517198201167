@use "src/shared/UI/styles/variables" as vars;

.widget-string-cell {
  &_first {
    padding-left: vars.$padding-sm !important;
  }

  &_edit {
    padding: 2px !important;
  }
}