@use "../base.module";

.divider {
  border-top-color: base.$divider-color;
  align-self: center;

  @include base.divider-width;
  @include base.divider-borders;

  &--vertical {
    position: relative;
    border-left: solid base.$divider-color;
    border-top: 0;
    align-self: initial;
  }
}