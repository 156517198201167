$separator-color: var(--color-text-secondary);

.separator {
  color: $separator-color;
  display: flex;
  align-items: center;
}

.chevron {
  transform: rotate(180deg);
  width: 0.7rem;
  height: 1.1rem;
}