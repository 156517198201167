@use "../../../ui/styles/constants.module";

.editor {
  width: constants.$editor-width;
  padding:
    constants.$editor-padding-top
    constants.$editor-padding-right
    constants.$editor-padding-bottom
    constants.$editor-padding-left;
  background: var(--color-white);
}