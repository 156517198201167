$date-picker-calendar-bg-color: var(--color-white);
$date-picker-calendar-cell-current-month-text-color: var(--color-text);
$date-picker-calendar-cell-disabled-bg-color: var(--color-additional-disabled);
$date-picker-calendar-cell-disabled-text-color: var(--color-main-disabled);
$date-picker-calendar-cell-hover-bg-color: var(--color-secondary-light-3);
$date-picker-calendar-cell-text-color: var(--color-text-secondary);
$date-picker-calendar-current-day-border-color: var(--color-primary);
$date-picker-calendar-selected-day-bg-color: var(--color-primary);
$date-picker-calendar-selected-day-text-color: var(--color-white);
$date-picker-calendar-th-text-color: var(--color-text);

$date-picker-header-border-color: var(--color-border);
$date-picker-header-button-color: var(--color-secondary);
$date-picker-header-button-hover-color: var(--color-primary);
$date-picker-header-text-color: var(--color-text);
$date-picker-header-text-hover-color: var(--color-primary);

$date-time-picker-cell-bg-hover-color: var(--color-secondary-light-3);
$date-time-picker-cell-selected-bg-color: var(--color-primary-light-4);
$date-time-picker-cell-text-color: var(--color-text);
$date-time-picker-cell-text-disabled-color: var(--color-main-disabled);
$date-time-picker-footer-bg-color: var(--color-white);
$date-time-picker-footer-border-color: var(--color-border);
$date-time-picker-footer-button-bg-color: var(--color-primary);
$date-time-picker-footer-button-text-color: var(--color-white);
$date-time-picker-footer-button-border-color: var(--color-primary);
$date-time-picker-header-text-color: var(--color-text);
$date-time-picker-panel-border-color: var(--color-border);

.ant-picker-panel-container {
  background: $date-picker-calendar-bg-color;
}

.ant-picker-header {
  border-bottom-color: $date-picker-header-border-color;
  color: $date-time-picker-header-text-color;

  .ant-picker-header-view button {
    color: $date-picker-header-text-color;

    &:hover {
      color: $date-picker-header-text-hover-color;
    }
  }

  button {
    color: $date-picker-header-button-color;

    &:hover {
      color: $date-picker-header-button-hover-color;
    }
  }
}

.ant-picker-content th {
  color: $date-picker-calendar-th-text-color;
}

.ant-picker-cell {
  color: $date-picker-calendar-cell-text-color;
}

.ant-picker-cell-in-view {
  color: $date-picker-calendar-cell-current-month-text-color;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: $date-picker-calendar-cell-hover-bg-color;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: $date-picker-calendar-current-day-border-color;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: $date-picker-calendar-selected-day-text-color;
  background: $date-picker-calendar-selected-day-bg-color;
}

.ant-picker-cell-disabled:before {
  background: $date-picker-calendar-cell-disabled-bg-color;
}

.ant-picker-cell-disabled {
  color: $date-picker-calendar-cell-disabled-text-color;
}

.ant-picker-footer button {
  background: $date-time-picker-footer-button-bg-color !important;
  color: $date-time-picker-footer-button-text-color !important;
  border-color: $date-time-picker-footer-button-border-color !important;
  box-shadow: none;
  text-shadow: none;
  &:hover {
    opacity: 0.7;
  }
}

.ant-picker-panel .ant-picker-footer {
  border-top-color: $date-time-picker-footer-border-color;
  background: $date-time-picker-footer-bg-color;
}

.ant-picker-panel {
  border-color: $date-time-picker-footer-border-color;
}

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-color: $date-time-picker-panel-border-color;
}

.ant-picker-time-panel-column:not(:first-child) {
  border-color: $date-time-picker-panel-border-color;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: $date-time-picker-cell-bg-hover-color;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: $date-time-picker-cell-selected-bg-color;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: $date-time-picker-cell-text-color;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: $date-time-picker-cell-text-disabled-color;
}
