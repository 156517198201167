@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

$notification-content-color: var(--color-text);
$notification-bg: var(--color-white);
$notification-success-bg: var(--color-success-light-3);
$notification-success-text-color: var(--color-success-dark-4);
$notification-error-bg: var(--color-danger-light-4);
$notification-error-text-color: var(--color-danger-dark-3);
$notification-warning-bg: var(--color-warning-light-4);
$notification-warning-text-color: var(--color-warning-dark-3);
$notification-info-bg: var(--color-primary-light-4);
$notification-info-text-color: var(--color-primary-dark-3);

$notification-border-radius: variables.$border-radius-sm;
$notification-min-width: 12.2rem;
$notification-max-width: 40rem;
$notification-padding: variables.$padding-md;

$notification-colors: (
  "success": (
    "background": $notification-success-bg,
    "color": $notification-success-text-color,
  ),
  "error": (
    "background": $notification-error-bg,
    "color": $notification-error-text-color,
  ),
  "warning": (
    "background": $notification-warning-bg,
    "color": $notification-warning-text-color,
  ),
  "info": (
    "background": $notification-info-bg,
    "color": $notification-info-text-color,
  ),
);

@mixin setNotificationColors($typeColors) {
  background: map-get($typeColors, "background");

  .ant-notification-notice-message, .ant-notification-notice-description {
    color: map-get($typeColors, "color");
  }
}

.ant-notification {
  color: $notification-content-color;
  box-shadow: none;
}

.ant-notification-notice {
  padding: $notification-padding;
  @include mixins.distant-box-shadow;
  width: unset;
  min-width: $notification-min-width;
  max-width: $notification-max-width;
  background: $notification-bg;
  border-radius: $notification-border-radius;

  @each $type, $typeColors in $notification-colors {
    &.ant-notification-notice-#{$type} {
      @include setNotificationColors($typeColors);
    }
  }
}

.ant-notification-notice-with-icon {
  .ant-notification-notice-message {
    @include mixins.font-size-16;
    margin-left: unset;
    padding-right: unset;
    white-space: pre-line;
  }

  .ant-notification-notice-description {
    @include mixins.font-size-14;
    margin-left: unset;
    white-space: pre-line;
  }
}

.ant-notification-notice-icon {
  display: none;
}

.ant-notification-notice-content {
  padding-right: 2.6rem;
}