@use "src/shared/UI/styles/mixins";

.link {
  display: block;
}

.tooltip-overlay {
  color: var(--color-white);
}

.tooltip-wrapper {
  overflow: hidden;
}

.tooltip-content {
  @include mixins.ellipsis;
}