@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

.links {
  height: 100%;

  .app-link {
    color: var(--color-secondary-dark-3);
  }

  .app-link-control {
    @include mixins.font-size-16;
    color: var(--color-primary);
  }

  .divider {
    height: 1.8rem;
    align-self: center;
  }

  .settings {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.avatar {
  &-img, &-empty {
    width: 2.14rem;
    height: 2.14rem;
  }
}
