$button-primary-bg-color: var(--color-primary);
$button-primary-border-color: var(--color-primary);
$button-primary-text-color: var(--color-white);

$button-primary-bg-active-color: var(--color-primary-dark-2);
$button-primary-border-active-color: var(--color-primary-dark-2);
$button-primary-text-active-color: var(--color-white);

$button-primary-bg-focus-color: var(--color-primary);
$button-primary-border-focus-color: var(--color-primary);
$button-primary-text-focus-color: var(--color-white);

$button-primary-bg-hover-color: var(--color-primary-light-2);
$button-primary-border-hover-color: var(--color-primary-light-2);
$button-primary-text-hover-color: var(--color-white);

$button-primary-bg-disabled-color: var(--color-additional-disabled);
$button-primary-border-disabled-color: var(--color-main-disabled);
$button-primary-text-disabled-color: var(--color-main-disabled);

.ant-btn-primary {
  border-color: $button-primary-border-color;
  background: $button-primary-bg-color;
  color: $button-primary-text-color;
}

.ant-btn-primary:focus {
  border-color: $button-primary-border-focus-color;
  background: $button-primary-bg-focus-color;
  color: $button-primary-text-focus-color;
}

.ant-btn-primary:hover {
  border-color: $button-primary-border-hover-color;
  background: $button-primary-bg-hover-color;
  color: $button-primary-text-hover-color;
}

.ant-btn-primary:active {
  border-color: $button-primary-border-active-color;
  background: $button-primary-bg-active-color;
  color: $button-primary-text-active-color;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  border-color: $button-primary-border-disabled-color;
  background-color: $button-primary-bg-disabled-color;
  color: $button-primary-text-disabled-color;
}