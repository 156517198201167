$tooltip-colors: (
  "primary": var(--color-primary),
  "secondary": var(--color-secondary),
  "success": var(--color-success),
  "warning": var(--color-warning),
  "danger": var(--color-danger),
);

.tooltip {
  @each $colorName, $color in $tooltip-colors {
    &.color-#{$colorName} {
      :global {
        .ant-tooltip-arrow-content::before {
          background: $color;
        }
        .ant-tooltip-inner {
          background-color: $color;
        }
      }
    }
  }
}