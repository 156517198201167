@use "src/shared/UI/styles/mixins";

.w-md-editor-text-pre > code,
.w-md-editor-text-input {
  @include mixins.font-size-16;
  font-family: inherit !important;
}

.w-md-editor-text {
  font-feature-settings: inherit;
  font-variant-ligatures: inherit;
}

.w-md-editor *::selection {
  background: rgba(39, 129, 245, 0.5);
}