.container {
  width: 100%;
}

.icon {
  width: 2rem;
  height: 2rem;

  &:hover {
    cursor: pointer;
    transition: all .2s;

    transform: scale(1.2);
    opacity: 0.7;
  }
}