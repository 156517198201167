@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

.list {
  & :global {
    .ant-form-item-explain-error {
      @include mixins.font-size-14;
    }
  }
}

.empty {
  text-align: center;
}
