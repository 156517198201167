$divider-color: var(--color-secondary-light-2);
$border-styles: solid, dashed, dotted;
$width-units: 100, 95, 90, 85, 80, 75;

@mixin divider-width {
  @each $unit in $width-units {
    &--#{$unit} {
      width: #{$unit}#{"%"}
    }
  }
}

@mixin divider-borders {
  @each $border in $border-styles {
    &--#{$border} {
      border-top-style: $border;
    }
  }
}