@use "src/shared/UI/styles/variables" as vars;
@use "../../styles" as const;

.content {
  flex: 1 1 0;
  min-width: 0;
  background: const.$content-bg;
  min-height: const.$content-min-height;
  padding: const.$content-padding;
  position: relative;
}

.wrapper {
  height: 100%;
}