@use "src/shared/UI/styles/variables" as vars;

.btn {
  position: absolute;
  top: 0;
  right: 0;
  width: auto !important;
  height: auto;
  padding: vars.$padding-xxl vars.$padding-xxl vars.$padding-md vars.$padding-md;

  :global(.app-button-icon-container) {
    svg {
      width: 1.4rem;
      height: 1.4rem;
      path {
        fill: var(--color-text);
      }
    }
  }

  &:hover {
    opacity: .7;
    :global(.app-button-icon-container) svg path {
      fill: var(--color-text);
    }
  }
}