@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

$tab-switch-separator-color: var(--color-border);
$tab-switch-primary-item-text-color: var(--color-text-secondary);
$tab-switch-primary-item-active-text-color: var(--color-primary-dark-1);
$tab-switch-primary-item-active-border-color: var(--color-primary-dark-1);

.container {
  display: flex;
  width: 87%;
  border-bottom: 2px solid $tab-switch-separator-color;
}

.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  cursor: default;
  transition: all .4s !important;
  @include mixins.font-size-16;
  font-family: variables.$primary-font-family;
  font-weight: 500;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
  padding: variables.$padding-xs variables.$padding-sm;

  color: $tab-switch-primary-item-text-color;

  &.active {
    color: $tab-switch-primary-item-active-text-color;
    border-bottom-color: $tab-switch-primary-item-active-border-color;
  }
}