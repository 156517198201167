@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/functions";
@use "src/shared/UI/styles/mixins";

$dropdown-menu-bg-color: var(--color-white);
$dropdown-menu-max-width: 20rem;
$dropdown-menu-sub-min-width: 10rem;
$dropdown-menu-sub-max-width: 20rem;

$dropdown-menu-item-bg-color: inherit;
$dropdown-menu-item-text-color: var(--color-text);
$dropdown-menu-item-hover-text-color: var(--color-primary);
$dropdown-menu-item-hover-bg-color: inherit;
$dropdown-menu-item-active-bg-color: var(--color-secondary-light-4);

$dropdown-menu-item-disabled-bg-color: inherit;
$dropdown-menu-item-disabled-text-color: var(--color-text-secondary);

$dropdown-menu-item-height: 3rem;
$dropdown-menu-item-line-height: 3rem;
$dropdown-menu-item-padding: 0;
$dropdown-menu-item-margin: 0.3rem;

.ant-dropdown-menu {
  padding: 0;
  margin: 0 !important;
  max-width: $dropdown-menu-max-width;
  background-color: $dropdown-menu-bg-color;
}

.ant-dropdown-menu.ant-dropdown-menu-sub {
  min-width: $dropdown-menu-sub-min-width;
  max-width: $dropdown-menu-sub-max-width;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
  padding: 0;
  background-color: $dropdown-menu-item-bg-color;
  color: $dropdown-menu-item-text-color;
}

.ant-dropdown-menu-title-content {
  @include mixins.ellipsis;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  height: $dropdown-menu-item-height;
  line-height: $dropdown-menu-item-line-height;
  padding: $dropdown-menu-item-padding;
  margin-top: $dropdown-menu-item-margin;
  margin-bottom: $dropdown-menu-item-margin;
}

.ant-dropdown-menu-item:not(:last-child),
.ant-dropdown-menu-submenu:not(:last-child) {
  margin-bottom: calc(#{$dropdown-menu-item-margin} * 2);
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title:hover{
  background-color: $dropdown-menu-item-hover-bg-color;
  color: $dropdown-menu-item-hover-text-color;
}

.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):active,
.ant-dropdown-menu-submenu:not(.ant-dropdown-menu-submenu-disabled) .ant-dropdown-menu-submenu-title:active {
  background-color: $dropdown-menu-item-active-bg-color;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:active,
.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:active {
  background: $dropdown-menu-item-disabled-bg-color;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-disabled,
.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
  color: $dropdown-menu-item-disabled-text-color !important;
  background: $dropdown-menu-item-disabled-bg-color;
}

.ant-dropdown-menu-submenu {
  z-index: variables.$z-index-dropdown;
}
