@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/colors/palette";
@use "./constants";

$table-min-row-height: 5.5rem;
//$table-min-height: $table-min-row-height * 8;
$table-small-min-row-height: 3.9rem;
$table-small-min-height: $table-small-min-row-height * 4;
$table-middle-min-row-height: 4.7rem;
$table-middle-min-height: $table-middle-min-row-height * 6;
$table-ping-left-box-shadow: inset 1rem 0 0.8rem -0.8rem rgba(palette.$black, 0.15);
$table-ping-right-box-shadow: inset -1rem 0 0.8rem -0.8rem rgba(palette.$black, 0.15);

$table-color: var(--color-text);
$table-bg-color: var(--color-white);

.ant-table-wrapper {
  background-color: $table-bg-color;

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: initial;
  }
}

.ant-table {
  color: $table-color;
  background-color: $table-bg-color;
  border-radius: constants.$table-border-radius;
  @include mixins.font-size-14;

  .ant-table-content {
    //min-height: $table-min-height;
    @include mixins.box-shadow-light-1;
    border-radius: constants.$table-border-radius;

    table {
      border-radius: constants.$table-border-radius;
    }
  }
}

.ant-table.ant-table-small {
  .ant-table-content {
    min-height: $table-small-min-height;
  }
}

.ant-table.ant-table-middle {
  .ant-table-content {
    min-height: $table-middle-min-height;
  }
}

.ant-table-ping-left .ant-table-cell-fix-left-first:after,
.ant-table-ping-left .ant-table-cell-fix-left-last:after,
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container:before {
  box-shadow: $table-ping-left-box-shadow;
}

.ant-table-ping-right .ant-table-cell-fix-right-first:after,
.ant-table-ping-right .ant-table-cell-fix-right-last:after,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
  box-shadow: $table-ping-right-box-shadow;
}