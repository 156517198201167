@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";
@use "../../styles" as const;

$hidden-width: 2.8rem + 2rem + 2.9rem;

.sidebar {
  flex: 0 0 const.$sidebar-width;
  min-height: const.$sidebar-min-height;
  background: const.$sidebar-bg;
  transition: all .3s;
  overflow: hidden;
}

.hidden {
  flex: 0 0 calc($hidden-width);
  overflow: hidden;
}

.sticky {
  overflow: visible !important;
  width: $hidden-width;
}

.icon-wrapper {
  transition: all .3s;
  margin-left: .5rem;
}

.icon-hidden {
  transform: rotate(180deg);
}

.control {
  padding: 1.8rem 2.9rem;
  height: const.$header-min-height;
  display: flex;
  align-items: center;
  width: 27.2rem;
  border-bottom: 1px solid var(--color-border);
  cursor: pointer;
  overflow: hidden;

  &:hover {
    opacity: 0.5;
  }
}