.loader {
  background-color: var(--color-white);
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  padding-top: 50vh;
}