@use "src/shared/UI/styles/variables/layout";

$base-button-font-size: 1.4rem;
$base-button-small-icon-size: 1.2rem;
$base-button-small-size: 3.2rem;
$base-button-middle-icon-size: 1.8rem;
$base-button-middle-size: 4rem;
$base-button-large-icon-size: 1.8rem;
$base-button-large-size: 4.8rem;
$button-default-size: "middle";

$button-sizes: (
  "small": (
    "font-size": $base-button-font-size,
    "icon-size": $base-button-small-icon-size,
    "height": $base-button-small-size,
    "padding": layout.$padding-xs layout.$padding-xl,
    "round-shape-horizontal-padding": layout.$padding-sm,
  ),
  "middle": (
    "font-size": $base-button-font-size,
    "icon-size": $base-button-middle-icon-size,
    "height": $base-button-middle-size,
    "padding": layout.$padding-sm layout.$padding-xl,
    "round-shape-horizontal-padding": layout.$padding-md,
  ),
  "large": (
    "font-size": $base-button-font-size,
    "icon-size": $base-button-large-icon-size,
    "height": $base-button-large-size,
    "padding": layout.$padding-md layout.$padding-xl,
    "round-shape-horizontal-padding": layout.$padding-lg,
  ),
);

@mixin setButtonSize($sizeValues) {
  font-size: map-get($sizeValues, "font-size");
  padding: map-get($sizeValues, "padding");
  height: map-get($sizeValues, "height");
  
  :global(.app-button-icon-container) {
    svg {
      width: map-get($sizeValues, "icon-size");
      height: map-get($sizeValues, "icon-size");
    }
  }
}

.button {
  $defaultSizeValues: map-get($button-sizes, $button-default-size);
  $sizesWithoutDefault: map-remove($button-sizes, $button-default-size);

  @include setButtonSize($defaultSizeValues);

  @each $size, $sizeValues in $sizesWithoutDefault {
    &.size-#{$size} {
      @include setButtonSize($sizeValues);
    }
  }
}