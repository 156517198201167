@use "src/shared/UI/styles/variables";

.container {
  border-radius: variables.$border-radius-md;
  height: 100%;
}

.hideToolbar {
  & :global(.se-toolbar) {
    display: none !important;
  }
}