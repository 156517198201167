@use "../mixins";

$button-outline-colors: (
  "primary": (
    "default": (
      "color": var(--color-primary),
      "border-color": var(--color-primary-light-4),
      "bg-color": var(--color-primary-light-4),
    ),
    "hover": (
      "color": var(--color-primary),
      "border-color": var(--color-primary-light-2),
      "bg-color": var(--color-primary-light-2),
    ),
    "active": (
      "color": var(--color-primary),
      "border-color": var(--color-primary-light-3),
      "bg-color": var(--color-primary-light-3),
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": var(--color-additional-disabled),
      "bg-color": var(--color-additional-disabled),
    ),
  ),
  "success": (
    "default": (
      "color": var(--color-success),
      "border-color": var(--color-success),
      "bg-color": var(--color-white),
    ),
    "hover": (
      "color": var(--color-success-light-2),
      "border-color": var(--color-success-light-2),
      "bg-color": var(--color-white),
    ),
    "active": (
      "color": var(--color-success-dark-1),
      "border-color": var(--color-success-dark-1),
      "bg-color": var(--color-white),
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": var(--color-additional-disabled),
      "bg-color": var(--color-additional-disabled),
    ),
  ),
  "warning": (
    "default": (
      "color": var(--color-warning),
      "border-color": var(--color-warning),
      "bg-color": var(--color-white),
    ),
    "hover": (
      "color": var(--color-warning-light-2),
      "border-color": var(--color-warning-light-2),
      "bg-color": var(--color-white),
    ),
    "active": (
      "color": var(--color-warning-dark-1),
      "border-color": var(--color-warning-dark-1),
      "bg-color": var(--color-white),
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": var(--color-additional-disabled),
      "bg-color": var(--color-additional-disabled),
    ),
  ),
  "danger": (
    "default": (
      "color": var(--color-danger),
      "border-color": var(--color-danger),
      "bg-color": var(--color-white),
    ),
    "hover": (
      "color": var(--color-danger-light-2),
      "border-color": var(--color-danger-light-2),
      "bg-color": var(--color-white),
    ),
    "active": (
      "color": var(--color-danger-dark-1),
      "border-color": var(--color-danger-dark-1),
      "bg-color": var(--color-white),
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": var(--color-additional-disabled),
      "bg-color": var(--color-additional-disabled),
    ),
  ),
);
$button-outline-default-color: "primary";
$button-dark-bg: var(--color-black);
$button-dark-disabled-text-color: var(--color-secondary-light-1);
$button-dark-disabled-bg: var(--color-black);

.type-outline {
  $defaultColorScheme: map-get($button-outline-colors, $button-outline-default-color);
  $colorsWithoutDefault: map-remove($button-outline-colors, $button-outline-default-color);

  @include mixins.colorizeButton($defaultColorScheme);

  @each $colorName, $colorScheme in $colorsWithoutDefault {
    &.color-#{$colorName} {
      @include mixins.colorizeButton($colorScheme);
    }
  }
}