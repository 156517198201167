@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$input-number-bg: var(--color-white);
$input-number-border: var(--color-border);
$input-number-border-hover: var(--color-primary-dark-1);
$input-number-border-focus: var(--color-primary-dark-1);
$input-number-text-color: var(--color-text);
$input-number-error-bg: var(--color-white);
$input-number-error-border: var(--color-danger);
$input-number-warning-bg: var(--color-white);
$input-number-warning-border: var(--color-warning);
$input-number-disabled-text-color: var(--color-main-disabled);
$input-number-disabled-border: var(--color-additional-disabled);
$input-number-disabled-bg: var(--color-additional-disabled);
$input-number-prefix-color: var(--color-main-disabled);
$input-number-disabled-out-of-range-text-color: var(--color-main-disabled);
$input-number-out-of-range-text-color: var(--color-danger);
$input-number-placeholder-color: var(--color-secondary);
$input-number-handler-bg: var(--color-white);
$input-number-handler-bg-active: var(--color-secondary-light-3);
$input-number-handler-border: var(--color-border);
$input-number-handler-arrow-color: var(--color-secondary);
$input-number-handler-arrow-color-hover: var(--color-primary);

.ant-input-number-affix-wrapper.ant-input-number-affix-wrapper-disabled .ant-input-number-prefix,
.ant-input-number-affix-wrapper.ant-input-number-affix-wrapper[disabled] .ant-input-number-prefix,
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper[disabled]) .ant-input-number-prefix {
  color: $input-number-prefix-color;
}

.ant-input-number-borderless.ant-input-number-disabled,
.ant-input-number-borderless.ant-input-number[disabled],
.ant-input-number-borderless:hover.ant-input-number-disabled,
.ant-input-number-borderless:hover.ant-input-number[disabled],
.ant-input-number-borderless:focus.ant-input-number-disabled,
.ant-input-number-borderless:focus.ant-input-number[disabled],
.ant-input-number-borderless-focused.ant-input-number-disabled,
.ant-input-number-borderless-focused.ant-input-number[disabled] {
  background-color: $input-number-disabled-bg;
}

.ant-input-number-affix-wrapper-borderless.ant-input-number-affix-wrapper-disabled,
.ant-input-number-affix-wrapper-borderless.ant-input-number-affix-wrapper[disabled],
.ant-input-number-affix-wrapper-borderless:hover.ant-input-number-affix-wrapper-disabled,
.ant-input-number-affix-wrapper-borderless:hover.ant-input-number-affix-wrapper[disabled],
.ant-input-number-affix-wrapper-borderless:focus.ant-input-number-affix-wrapper-disabled,
.ant-input-number-affix-wrapper-borderless:focus.ant-input-number-affix-wrapper[disabled],
.ant-input-number-affix-wrapper-borderless-focused.ant-input-number-affix-wrapper-disabled,
.ant-input-number-affix-wrapper-borderless-focused.ant-input-number-affix-wrapper[disabled] {
  background-color: $input-number-disabled-bg;
}

.ant-input-number, .ant-input-number-affix-wrapper {
  width: 100%;
  color: $input-number-text-color;
  border-radius: variables.$border-radius-md;
  border-color: $input-number-border;
  background-color: $input-number-bg;
  @include mixins.font-size-16;
  &:hover {
    border-color: $input-number-border-hover;
  }

  .ant-input-number-input-wrap, input {
    height: 100%;
  }
}
.ant-input-number {
  height: 4rem;
}
.ant-input-number-affix-wrapper {
  .ant-input-number {
    height: 3.8rem;
  }
}

.ant-input-number-input::placeholder {
  color: $input-number-placeholder-color;
}

.ant-input-number-focused, .ant-input-number:focus {
  box-shadow: none;
  border-color: $input-number-border-focus;
}

.ant-input-number-handler-wrap {
  background-color: $input-number-handler-bg;
}

.ant-input-number-handler {
  border-color: $input-number-handler-border;
  &:active {
    background-color: $input-number-handler-bg-active;
  }
}

.ant-input-number-handler-down-inner, .ant-input-number-handler-up-inner {
  color: $input-number-handler-arrow-color;
}

.ant-input-number-handler:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler:hover .ant-input-number-handler-up-inner {
  color: $input-number-handler-arrow-color-hover;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background-color: $input-number-error-bg;
  border-color: $input-number-error-border;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus {
  background-color: $input-number-error-bg;
  border-color: $input-number-error-border;
  box-shadow: none;
}

.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background-color: $input-number-warning-bg;
  border-color: $input-number-warning-border;
}

.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus {
  background-color: $input-number-warning-bg;
  border-color: $input-number-warning-border;
  box-shadow: none;
}

.ant-input-number-out-of-range input {
  color: $input-number-out-of-range-text-color;
}

.ant-input-number-out-of-range.ant-input-number-disabled input,
.ant-input-number-out-of-range.ant-input-number[disabled] input {
  color: $input-number-disabled-out-of-range-text-color;
}

.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: $input-number-border-hover;
}

.ant-input-number-affix-wrapper-focused, .ant-input-number-affix-wrapper:focus {
  border-color: $input-number-border-focus;
  box-shadow: none;
}

.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: $input-number-error-bg;
  border-color: $input-number-error-border;
}

.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus {
  background: $input-number-error-bg;
  border-color: $input-number-error-border;
  box-shadow: none;
}

.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: $input-number-warning-bg;
  border-color: $input-number-warning-border;
}

.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus {
  background: $input-number-warning-bg;
  border-color: $input-number-warning-border;
  box-shadow: none;
}

.ant-input-number-disabled {
  color: $input-number-disabled-text-color;
  border-color: $input-number-disabled-border;
  background-color: $input-number-disabled-bg;
}

.ant-input-number-disabled:hover {
  border-color: $input-number-disabled-border;
}

.ant-input-number-affix-wrapper-disabled {
  color: $input-number-disabled-text-color;
  border-color: $input-number-disabled-border;
  background-color: $input-number-disabled-bg;
}

.ant-input-number-affix-wrapper-disabled:hover {
  border-color: $input-number-disabled-border;
}