@use "src/shared/UI/styles/variables" as vars;

.left {
  text-align: left;
}

.cell {
  padding: vars.$padding-sm;
}

.boldCell {
  font-weight: 700;
}

.textColumn {
  width: 20%;
  text-align: left;
}

.parentCellForGrades {
  width: 20%;
}

.center {
  text-align: center;
}