@use './constants.module';

$contour-default-offset: constants.$contour-line-height;
$contour-default-color: var(--color-primary-dark-1);

@mixin contour(
  $offset-top: $contour-default-offset,
  $offset-right: $contour-default-offset,
  $offset-bottom: $contour-default-offset,
  $offset-left: $contour-default-offset,
  $color: $contour-default-color,
) {
  &:after {
    content: "";
    position: absolute;
    top: -$offset-top;
    right: -$offset-right;
    bottom: -$offset-bottom;
    left: -$offset-left;
    z-index: constants.$contour-z-index;
    pointer-events: none;

    background-image:
      linear-gradient(to right, #{$color} 50%, transparent 50%),
      linear-gradient(to right, #{$color} 50%, transparent 50%),
      linear-gradient(to bottom, #{$color} 50%, transparent 50%),
      linear-gradient(to bottom, #{$color} 50%, transparent 50%);
    background-position:
      left top,
      left bottom,
      left top,
      right top;
    background-repeat:
      repeat-x,
      repeat-x,
      repeat-y,
      repeat-y;
    background-size:
      constants.$contour-line-width constants.$contour-line-height,
      constants.$contour-line-width constants.$contour-line-height,
      constants.$contour-line-height constants.$contour-line-width,
      constants.$contour-line-height constants.$contour-line-width;
  }
}

@mixin contour-back-block() {
  &:before {
    $offset-x: calc(#{constants.$contour-line-height} + #{constants.$control-panel-offset-x});

    content: "";
    position: absolute;
    top: 0;
    right: calc(-1 * $offset-x);
    bottom: 0;
    left: calc(-1 * $offset-x);
    display: block;
    background: transparent;
  }
}