@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";
@use "./constants";

$table-header-cell-padding: vars.$padding-md;
$table-small-header-cell-padding: vars.$padding-xs;
$table-middle-header-cell-padding: vars.$padding-sm vars.$padding-xs;

$table-header-cell-color: var(--color-primary-dark-3);
$table-header-cell-background: var(--color-primary-light-4);

.ant-table-thead > tr {
  vertical-align: top;

  > th {
    padding: $table-header-cell-padding;
    color: $table-header-cell-color;
    background: $table-header-cell-background;
    border-bottom: unset;
    @include mixins.font-size-14;
    font-weight: 500;

    &:first-child {
      border-left-color: constants.$table-outer-border-color !important;
    }
    &:last-child {
      border-right-color: constants.$table-outer-border-color !important;
    }
  }

  &:first-child {
    th {
      border-top-color: constants.$table-outer-border-color !important;

      &:first-child {
        border-top-left-radius: constants.$table-border-radius !important;
      }
      &:last-child {
        border-top-right-radius: constants.$table-border-radius !important;
      }
    }
  }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background-color: var(--color-border);
}

.ant-table.ant-table-small {
  .ant-table-thead > tr > th {
    padding: $table-small-header-cell-padding;
  }
}

.ant-table.ant-table-middle {
  .ant-table-thead > tr > th {
    padding: $table-middle-header-cell-padding;
  }
}