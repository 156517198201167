@use '../mixins';

* {
  font-family: "Inter", sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  @include mixins.font-size-14;
}

html, body, #root {
  height: 100%;
  width: 100%;
}
