@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";
@use "./constants";

$table-body-cell-padding: vars.$padding-md;
$table-small-body-cell-padding: vars.$padding-xs;
$table-middle-body-cell-padding: vars.$padding-sm vars.$padding-xs;

$table-summary-bg: inherit;
$table-body-row-hover-bg: var(--color-primary-light-4);

.ant-table-tbody {
  > tr {
    > td {
      padding: $table-body-cell-padding;
      border-bottom: 1px solid var(--color-border);
      @include mixins.font-size-14;
      font-weight: 500;
      overflow-wrap: break-word;

      &:first-child {
        border-left-color: constants.$table-outer-border-color !important;
      }
      &:last-child {
        border-right-color: constants.$table-outer-border-color !important;
      }
    }

    &:last-child {
      td {
        border-bottom-color: constants.$table-outer-border-color !important;

        &:first-child {
          border-bottom-left-radius: constants.$table-border-radius !important;
        }
        &:last-child {
          border-bottom-right-radius: constants.$table-border-radius !important;
        }
      }
    }
  }

  > tr.ant-table-row:hover > td,
  > tr > td.ant-table-cell-row-hover {
    background: $table-body-row-hover-bg;
  }
}

.ant-table-summary {
  background: $table-summary-bg;
}

.ant-table.ant-table-small {
  .ant-table-tbody > tr > td {
    padding: $table-small-body-cell-padding;
  }
}

.ant-table.ant-table-middle {
  .ant-table-tbody > tr > td {
    padding: $table-middle-body-cell-padding;
  }
}

.ant-table.ant-table-bordered > .ant-table-container {
  border-left: unset;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: unset;
}

.ant-table.ant-table-bordered > .ant-table-container {
  > .ant-table-body > table > tbody > tr > td,
  > .ant-table-body > table > tfoot > tr > td,
  > .ant-table-body > table > tfoot > tr > th,
  > .ant-table-body > table > thead > tr > th,
  > .ant-table-content > table > tbody > tr > td,
  > .ant-table-content > table > tfoot > tr > td,
  > .ant-table-content > table > tfoot > tr > th,
  > .ant-table-content > table > thead > tr > th,
  > .ant-table-header > table > tbody > tr > td,
  > .ant-table-header > table > tfoot > tr > td,
  > .ant-table-header > table > tfoot > tr > th,
  > .ant-table-header > table > thead > tr > th,
  > .ant-table-summary > table > tbody > tr > td,
  > .ant-table-summary > table > tfoot > tr > td,
  > .ant-table-summary > table > tfoot > tr > th,
  > .ant-table-summary > table > thead > tr > th {
    border-right: 1px solid var(--color-border);

    &:first-child {
      border-left: 1px solid var(--color-border);
    }
  }
}

.ant-table.ant-table-bordered > .ant-table-container {
  > .ant-table-body > table > thead > tr:not(:last-child) > th,
  > .ant-table-content > table > thead > tr:not(:last-child) > th,
  > .ant-table-header > table > thead > tr:not(:last-child) > th,
  > .ant-table-summary > table > thead > tr:not(:last-child) > th {
    border-bottom: 1px solid var(--color-border);
  }
}

.ant-table.ant-table-bordered > .ant-table-container {
  > .ant-table-body > table > thead > tr:first-child > th,
  > .ant-table-content > table > thead > tr:first-child > th,
  > .ant-table-header > table > thead > tr:first-child > th,
  > .ant-table-summary > table > thead > tr:first-child > th {
    border-top: 1px solid var(--color-border);
  }
}