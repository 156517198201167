@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

.spinner {
  @include mixins.stretched;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: variables.$z-index-block;
}