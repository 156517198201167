.input-date-range {
  padding: 0.4rem 1.1rem !important;

  :global(.ant-picker-input) {
    width: 6.8rem;
  }

  :global(.ant-picker-clear) {
    right: 1px !important;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}