@use "../../styles" as const;

.footer {
  background: const.$footer-bg;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: const.$footer-padding;
}

.text {
  color: var(--color-white);
}