@use "src/shared/UI/styles/mixins";

.buttons {
  width: 30%;
  justify-content: flex-end;
}

.gap {
  width: 40px;
}

.removeIcon rect,
.removeIcon path {
  fill: var(--color-primary) !important;
  stroke: var(--color-white) !important;
}

.createButton {
  align-self: flex-start;
}
