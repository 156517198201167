@use "shared/UI/styles/variables";
@use "shared/UI/styles/mixins";

.card {
  padding: variables.$padding-lg variables.$padding-md;
  border: 1px solid var(--color-border);
  border-radius: variables.$border-radius-md;
  transition: .3s all;
  @include mixins.font-size-18();
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: variables.$margin-xl !important;
  }

  &:hover {
    border-color: var(--color-primary-light-1);
  }

  & :global(.ant-checkbox-inner) {
    width: 1.9rem;
    height: 1.9rem;
  }

  & :global(.ant-checkbox-inner:after) {
    height: 1.1rem;
  }

  & :global(.ant-checkbox + span) {
    padding-left: variables.$padding-md !important;
  }
}