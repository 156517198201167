@use "src/shared/UI/styles/variables";

$confirm-bg-color: var(--color-white);
$confirm-bg-arrow-color: var(--color-white);
$confirm-icon-color: var(--color-warning);
$confirm-text-color: var(--color-text);

.ant-popover {
  z-index: variables.$z-index-popover;
}

.ant-popover-inner-content {
  background: $confirm-bg-color;
}

.ant-popover-message > .anticon {
  color: $confirm-icon-color;
}

.ant-popover-message {
  color: $confirm-text-color;
}

.ant-popover-arrow-content:before,
.ant-popover-arrow-content{
  background: $confirm-bg-arrow-color;
}