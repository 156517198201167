@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins/breakpoints";
@use "../../styles/constants.module" as const;

@mixin move-child($top: unset, $right: unset, $bottom: unset, $left: unset) {
  > div {
    top: #{$top};
    right: #{$right};
    bottom: #{$bottom};
    left: #{$left};
  }
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: vars.$z-index-modal;
  pointer-events: none;
  outline: none;
  display: flex;
  flex-direction: column;
  will-change: transform;

  &_placement {
    &_top-left {
      justify-content: start;
      align-items: start;
      @include move-child($top: const.$dialog-side-indent, $left: const.$dialog-side-indent);
    }
    &_top {
      justify-content: start;
      align-items: center;
      @include move-child($top: const.$dialog-side-indent);
    }
    &_top-right {
      justify-content: start;
      align-items: end;
      @include move-child($top: const.$dialog-side-indent, $right: const.$dialog-side-indent);
    }
    &_left {
      justify-content: center;
      align-items: start;
      @include move-child($left: const.$dialog-side-indent);
    }
    &_center {
      justify-content: center;
      align-items: center;
    }
    &_right {
      justify-content: center;
      align-items: end;
      @include move-child($right: const.$dialog-side-indent);
    }
    &_bottom-left {
      justify-content: end;
      align-items: start;
      @include move-child($bottom: const.$dialog-side-indent, $left: const.$dialog-side-indent);
    }
    &_bottom {
      justify-content: end;
      align-items: center;
      @include move-child($bottom: const.$dialog-side-indent);
    }
    &_bottom-right {
      justify-content: end;
      align-items: end;
      @include move-child($bottom: const.$dialog-side-indent, $right: const.$dialog-side-indent);
    }
  }

  &_visible {
    &_init {
      transform: translate(100vw, 100vh);
    }
    &_active {
      transform: translate(0px, 0px);
      transition-property: transform;
      transition-duration: const.$wrapper-transition-duration;
      transition-timing-function: ease-in;
    }
    &_done {
      transform: translate(0px, 0px);
    }
  }

  &_hidden, &_destroyed {
    &_init {
      transform: translate(0px, 0px);
    }
    &_active {
      transform: translate(100vw, 100vh);
      transition-property: transform;
      transition-duration: const.$wrapper-transition-duration;
      transition-timing-function: ease-out;
    }
    &_done {
      transform: translate(100vw, 100vh);
    }
  }

  @include breakpoints.mobile-screen-size {
    justify-content: start;
    align-items: start;
    @include move-child();
  }
}