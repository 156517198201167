$table-expanded-row-bg: var(--color-white);
$table-expanded-row-hover-bg: var(--color-primary-light-4);
$table-expand-icon-bg: var(--color-white);
$table-expand-icon-hover-color: var(--color-primary-light-1);
$table-expand-icon-collapsed-elements-bg: var(--color-primary);
$table-expand-icon-expanded-elements-bg: var(--color-secondary);

tr.ant-table-expanded-row > td {
  background: $table-expanded-row-bg;
}

tr.ant-table-expanded-row:hover > td {
  background: $table-expanded-row-hover-bg;
}

.ant-table-row-expand-icon {
  background: $table-expand-icon-bg;

  &:focus,
  &:hover {
    color: $table-expand-icon-hover-color;
  }

  &.ant-table-row-expand-icon-collapsed {
    &:before,
    &:after {
      background: $table-expand-icon-collapsed-elements-bg;
    }
  }

  &.ant-table-row-expand-icon-expanded {
    &:before,
    &:after {
      background: $table-expand-icon-expanded-elements-bg;
    }
  }
}