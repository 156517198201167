@use "src/shared/UI/styles/variables/layout";

$radio-outer-size: 2rem;
$radio-outer-border-width: 1px;
$radio-inner-size: 2rem;
$radio-label-padding: layout.$padding-xs;

$radio-label-color: var(--color-text);
$radio-label-disabled-color: var(--color-main-disabled);

$radio-bg-color: var(--color-white);
$radio-outer-inner-color: var(--color-border);
$radio-outer-inner-checked-color: var(--color-primary);
$radio-outer-inner-hover-color: var(--color-primary);

$radio-bg-disabled-color: var(--color-additional-disabled);
$radio-outer-inner-disabled-color: var(--color-main-disabled);

.ant-radio-wrapper {
  align-items: center;
  margin: 0;
}

.ant-radio {
  top: unset;

  & + span {
    padding: 0 $radio-label-padding;
    color: $radio-label-color;
  }

  &.ant-radio-disabled {
    & + span {
      color: $radio-label-disabled-color;
    }
  }
}

.ant-radio-inner {
  width: $radio-outer-size;
  height: $radio-outer-size;
  border-width: $radio-outer-border-width;
  border-color: $radio-outer-inner-color !important;
  background-color: $radio-bg-color;
  box-shadow: none !important;

  &:after {
    width: $radio-inner-size;
    height: $radio-inner-size;
    margin-top: calc(#{-$radio-inner-size} / 2);
    margin-left: calc(#{-$radio-inner-size} / 2);
    border-radius: $radio-inner-size;
    background-color: $radio-outer-inner-color;
  }
}

.ant-radio-input:not([disabled]):focus + .ant-radio-inner,
.ant-radio-wrapper:not([class*="ant-radio-wrapper-disabled"]):hover .ant-radio-inner,
.ant-radio:not([class*="ant-radio-disabled"]):hover .ant-radio-inner {
  border-color: $radio-outer-inner-hover-color !important;

  &:after {
    background-color: $radio-outer-inner-hover-color;
  }
}

.ant-radio-wrapper:not([class*="ant-radio-wrapper-disabled"]):hover .ant-radio-checked:after {
  border-color: $radio-outer-inner-hover-color !important;
}

.ant-radio-checked:not([class*="ant-radio-disabled"]) .ant-radio-inner {
  border-color: $radio-outer-inner-checked-color !important;

  &:after {
    background-color: $radio-outer-inner-checked-color;
  }
}

.ant-radio-checked:after {
  animation: none !important;
}

.ant-radio-disabled .ant-radio-inner {
  background-color: $radio-bg-disabled-color;
  border-color: $radio-outer-inner-disabled-color !important;
}

.ant-radio-wrapper-disabled:hover .ant-radio-disabled {
  &:after {
    border-color: $radio-outer-inner-disabled-color;
  }
}