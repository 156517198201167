@mixin font($font-family, $font-file, $font-weight) {
  @font-face {
    font-family: $font-family;
    src: url($font-file) format('woff2');
    font-weight: $font-weight;
    font-style: normal;
    font-display: fallback;
  }
}

@include font('Inter', '../../assets/fonts/inter-cyrillic-100.woff2', 100);
@include font('Inter', '../../assets/fonts/inter-cyrillic-200.woff2', 200);
@include font('Inter', '../../assets/fonts/inter-cyrillic-300.woff2', 300);
@include font('Inter', '../../assets/fonts/inter-cyrillic-400.woff2', 400);
@include font('Inter', '../../assets/fonts/inter-cyrillic-500.woff2', 500);
@include font('Inter', '../../assets/fonts/inter-cyrillic-600.woff2', 600);
@include font('Inter', '../../assets/fonts/inter-cyrillic-700.woff2', 700);
@include font('Inter', '../../assets/fonts/inter-cyrillic-800.woff2', 800);
@include font('Inter', '../../assets/fonts/inter-cyrillic-900.woff2', 900);