@use "src/shared/UI/styles/variables" as vars;

$modal-content-scroll-track-bg: inherit;

.content {
  padding: 0 vars.$padding-xxxl;
  flex: 1;
  overflow-y: auto;

  &:first-child {
    padding: vars.$padding-lg vars.$padding-lg 0 vars.$padding-lg;
  }

  &:only-child {
    padding: vars.$padding-lg;
  }

  &::-webkit-scrollbar-track {
    background-color: $modal-content-scroll-track-bg;
  }
}