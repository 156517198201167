$checkbox-bg-color: var(--color-white);
$checkbox-bg-checked-color: var(--color-primary);
$checkbox-bg-disabled-color: var(--color-additional-disabled);
$checkbox-bg-indeterminate-color: var(--color-primary);

$checkbox-border-color: var(--color-border);
$checkbox-border-focus-color: var(--color-secondary);
$checkbox-checked-border-focus-color: var(--color-primary);
$checkbox-border-checked-color: var(--color-primary);
$checkbox-border-hover-color: var(--color-primary);
$checkbox-border-disabled-color: var(--color-main-disabled);

$checkbox-label-color: var(--color-text);
$checkbox-label-disabled-color: var(--color-main-disabled);

$checkbox-mark-color: var(--color-white);
$checkbox-mark-disabled-color: var(--color-main-disabled);

.ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.ant-checkbox-inner {
  border-color: $checkbox-border-color;
  background-color: $checkbox-bg-color;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $checkbox-bg-indeterminate-color;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $checkbox-bg-checked-color;
  border-color: $checkbox-border-checked-color;
}

.ant-checkbox-checked:after {
  border-color: $checkbox-border-checked-color;
}

.ant-checkbox + span {
  color: $checkbox-label-color;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: $checkbox-mark-color;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $checkbox-border-focus-color;
}

.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $checkbox-checked-border-focus-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $checkbox-border-hover-color;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: $checkbox-bg-disabled-color;
  border-color: $checkbox-border-disabled-color !important;
}

.ant-checkbox-disabled + span {
  color: $checkbox-label-disabled-color;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: $checkbox-mark-disabled-color;
}
