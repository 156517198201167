$colors: (
  "primary": var(--color-primary),
  "secondary": var(--color-secondary),
  "success": var(--color-success),
  "warning": var(--color-warning),
  "danger": var(--color-danger),
);

.loader {
  @each $cn, $cv in $colors {
    &.color-#{$cn} {
      color: $cv;
      :global(.ant-spin-dot-item) {
        background-color: $cv;
      }
    }
  }

  &--stretched {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}