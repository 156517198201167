@use "src/shared/UI/styles/variables";

$preview-width: 5rem;
$preview-bg-size: 2.5rem;
$preview-bg-color: var(--color-secondary-light-4);
$preview-bg-image: url("../../../../assets/images/img-preview.svg");

.image {
  width: 100%;
  background-position: center center !important;
  background: transparent;
  background-repeat: no-repeat;
}

.preview {
  width: $preview-width;
  background-size: $preview-bg-size;
  background-image: $preview-bg-image;
  background-color: $preview-bg-color;
  border-radius: variables.$border-radius-sm;
}