@use "src/shared/UI/styles/variables" as vars;

$editor-wrapper-bg-color: var(--color-white);
$editor-width: 29.7cm;

.editor {
  width: $editor-width;
  background: $editor-wrapper-bg-color;
  padding: 1.5cm 1cm 1.5cm 2.5cm;
}

.title {
  margin-bottom: vars.$margin-md !important;
}