.sticky-wrap {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sticky-button {
  position: sticky;
  top: 20px;
  left: 0;
  z-index: 10;
}

.button {
  width: 140px;
}

.prevIcon {
  transform: rotate(-90deg);
}

.nextIcon {
  transform: rotate(90deg);
}