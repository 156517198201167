$vertical-menu-item-height: 3rem;
$vertical-menu-item-line-height: 3rem;
$vertical-menu-item-padding: 0;
$vertical-menu-item-margin: 0.3rem;

.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  height: $vertical-menu-item-height;
  line-height: $vertical-menu-item-line-height;
  padding: $vertical-menu-item-padding;
  margin-top: $vertical-menu-item-margin;
  margin-bottom: $vertical-menu-item-margin;
}

.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: $vertical-menu-item-height;
  line-height: $vertical-menu-item-line-height;
}

.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-submenu:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-submenu:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-submenu:not(:last-child) {
  margin-bottom: calc(#{$vertical-menu-item-margin} * 2);
}

.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu {
  padding-bottom: 0;
}

.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: $vertical-menu-item-padding;
}