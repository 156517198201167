.vertical {
  display: flex;
  flex-direction: column;

  & :global(.ant-checkbox-wrapper):not(:last-child) {
    margin-bottom: 1rem;
  }
}


