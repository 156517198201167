$multiple-select-checked-item-mark-color: var(--color-primary-dark-1);
$multiple-select-selected-item-bg-color: var(--color-secondary-light-4);
$multiple-select-selected-item-border-color: var(--color-secondary-light-2);
$multiple-select-selected-item-remove-color: var(--color-secondary-dark-2);
$multiple-select-selected-item-remove-hover-color: var(--color-secondary);

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: $multiple-select-checked-item-mark-color;
}

.ant-select-multiple .ant-select-selection-item {
  background: $multiple-select-selected-item-bg-color;
  border-color: $multiple-select-selected-item-border-color;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: $multiple-select-selected-item-remove-color;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: $multiple-select-selected-item-remove-hover-color;
}

.ant-select-multiple {
  height: unset;
  min-height: 4rem;
  .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
    line-height: 2.2rem;
  }
}