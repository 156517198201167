@use "src/shared/UI/styles/mixins";

.control {
  & :global label  {
    font-weight: 400;
    @include mixins.font-size-18;
  }

  & :global .ant-form-item-label {
    padding-bottom: 0;

    & > label {
      height: auto;
    }
  }

  & :global .ant-form-item-row {
    align-items: flex-start;
  }

  & :global .ant-form-item-required::before {
    display: none !important;
  }
}

.container {
  width: 100%;
}

.icon path {
  stroke: var(--color-white) !important;
  fill: var(--color-primary) !important;
  stroke-width: 3;

  &:last-of-type {
    fill: var(--color-white) !important;
    stroke-width: 1;
  }
}

.warning {
  outline: var(--color-warning) solid 2px;
  border-radius: .4rem;
}
