$date-picker-input-bg: var(--color-white);
$date-picker-input-border: var(--color-border);
$date-picker-input-border-hover: var(--color-primary);
$date-picker-input-border-focus: var(--color-primary);
$date-picker-input-border-error: var(--color-danger);
$date-picker-input-border-warning: var(--color-warning);
$date-picker-input-clear-bg: var(--color-secondary-dark-2);
$date-picker-input-clear-bg-hover: var(--color-secondary);
$date-picker-input-disabled-text-color: var(--color-main-disabled);
$date-picker-input-disabled-bg-color: var(--color-additional-disabled);
$date-picker-input-disabled-border-color: var(--color-main-disabled);
$date-picker-input-suffix-color: var(--color-secondary);
$date-picker-input-text-color: var(--color-text);

.ant-picker {
  width: 100%;
  box-shadow: none !important;
  background: $date-picker-input-bg;
  border-color: $date-picker-input-border;

  &:hover {
    border-color: $date-picker-input-border-hover;
  }
}

.ant-picker-focused {
  border-color: $date-picker-input-border-focus;
}

.ant-picker-input > input {
  color: $date-picker-input-text-color;
}

.ant-picker-status-error {
  border-color: $date-picker-input-border-error;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  border-color: $date-picker-input-border-error;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not([disabled]):hover,
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: $date-picker-input-border-warning;
}

.ant-picker-suffix {
  color: $date-picker-input-suffix-color;
}

.ant-picker-clear {
  color: $date-picker-input-clear-bg;

  &:hover {
    color: $date-picker-input-clear-bg-hover;
  }
}

.ant-picker.ant-picker-disabled {
  background: $date-picker-input-disabled-bg-color;
  border-color: $date-picker-input-disabled-border-color;
}

.ant-picker-input > input[disabled] {
  color: $date-picker-input-disabled-text-color;
}
