$button-default-bg-color: var(--color-white);
$button-default-border-color: var(--color-border);
$button-default-text-color: var(--color-text);

$button-default-bg-active-color: var(--color-white);
$button-default-border-active-color: var(--color-primary-dark-2);
$button-default-text-active-color: var(--color-primary-dark-2);

$button-default-bg-focus-color: var(--color-white);
$button-default-border-focus-color: var(--color-border);
$button-default-text-focus-color: var(--color-text);

$button-default-bg-hover-color: var(--color-white);
$button-default-border-hover-color: var(--color-primary);
$button-default-text-hover-color: var(--color-primary);

$button-default-bg-disabled-color: var(--color-additional-disabled);
$button-default-border-disabled-color: var(--color-main-disabled);
$button-default-text-disabled-color: var(--color-main-disabled);

.ant-btn {
  border-color: $button-default-border-color;
  background: $button-default-bg-color;
  color: $button-default-text-color;
}

.ant-btn:focus {
  border-color: $button-default-border-focus-color;
  background: $button-default-bg-focus-color;
  color: $button-default-text-focus-color;
}

.ant-btn:hover {
  border-color: $button-default-border-hover-color;
  background: $button-default-bg-hover-color;
  color: $button-default-text-hover-color;
}

.ant-btn:active {
  border-color: $button-default-border-active-color;
  background: $button-default-bg-active-color;
  color: $button-default-text-active-color;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  border-color: $button-default-border-disabled-color;
  background-color: $button-default-bg-disabled-color;
  color: $button-default-text-disabled-color;
}