@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

$menu-item-group-title-padding: variables.$padding-xs variables.$padding-sm;
$menu-item-group-list-padding: 0 0 0 variables.$padding-sm;

$menu-item-group-title-color: var(--color-text-secondary);

.ant-menu-item-group-title {
  padding: $menu-item-group-title-padding;
  color: $menu-item-group-title-color;
  font-weight: 500;
  @include mixins.font-size-12;
}

.ant-menu-item-group-list {
  padding: $menu-item-group-list-padding !important;
}
