@use "src/shared/UI/styles/mixins";

$menu-item-bg-color: var(--color-white);
$menu-item-text-color: var(--color-text);
$menu-item-text-hover-color: var(--color-primary);
$menu-item-active-bg-color: var(--color-secondary-light-3);

$menu-item-selected-bg-color: var(--color-primary-light-4);
$menu-item-selected-text-color: var(--color-text);

$menu-item-selected-disabled-bg-color: var(--color-primary-light-3);
$menu-item-selected-disabled-text-color: var(--color-text-secondary);

$menu-item-disabled-bg-color: var(--color-white);
$menu-item-disabled-text-color: var(--color-text-secondary);

$menu-item-danger-text-color: var(--color-danger);
$menu-item-danger-selected-bg-color: var(--color-danger-light-4);
$menu-item-danger-selected-disabled-bg-color: var(--color-danger-light-3);
$menu-item-danger-disabled-color: var(--color-danger-light-1);

.ant-menu-item,
.ant-menu-submenu-title {
  display: flex;
  align-items: center;
  background: $menu-item-bg-color;
  color: $menu-item-text-color;
}

.ant-menu-title-content {
  flex: 1;
  @include mixins.ellipsis;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: $menu-item-text-hover-color;
}

.ant-menu-item:not(.ant-menu-item-disabled):active,
.ant-menu-submenu:not(.ant-menu-submenu-disabled) .ant-menu-submenu-title:active {
  background: $menu-item-active-bg-color;
}

.ant-menu-item.ant-menu-item-disabled:active,
.ant-menu-submenu-disabled .ant-menu-submenu-title:active {
  background: $menu-item-disabled-bg-color;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $menu-item-selected-text-color;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: $menu-item-selected-bg-color;
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled,
.ant-menu-submenu-disabled .ant-menu-submenu-title {
  color: $menu-item-disabled-text-color !important;
  background: $menu-item-disabled-bg-color;
}

.ant-menu-item-selected.ant-menu-item-disabled {
  color: $menu-item-selected-disabled-text-color !important;
  background: $menu-item-selected-disabled-bg-color !important;

  &.ant-menu-item-danger {
    background: $menu-item-danger-selected-disabled-bg-color !important;
  }
}

.ant-menu-item-danger.ant-menu-item,
.ant-menu-item-danger.ant-menu-item-active,
.ant-menu-item-danger.ant-menu-item:hover {
  color: $menu-item-danger-text-color;

  &.ant-menu-item-disabled {
    color: $menu-item-danger-disabled-color !important;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background: $menu-item-danger-selected-bg-color;
}