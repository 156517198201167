.pagination {
  text-align: left;

  & :global(.ant-pagination-total-text) {
    position: absolute;
    right: 0;
  }
}

.remove {
  cursor: pointer;
  width: 2rem;
  height: 2rem;

  &:hover {
    opacity: 0.5;
  }
}

.removeContainer {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-black);
}

.name {
  width: 90%;
}

