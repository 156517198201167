.header-row {
  &_selected {
    td {
      box-shadow: -1px -1px 0px var(--color-primary-dark-1);
      border-bottom: 1px solid var(--color-primary-dark-1) !important;
      border-right: 1px solid var(--color-primary-dark-1) !important;
    }
  }
}

.body-row {
  &_selected {
    td {
      box-shadow: 0 -1px 0px var(--color-primary-dark-1);
      border-bottom: 1px solid var(--color-primary-dark-1) !important;

      &:first-child {
        box-shadow: -1px -1px 0px var(--color-primary-dark-1);
      }
      &:last-child {
        border-right: 1px solid var(--color-primary-dark-1);
      }
    }
  }
}