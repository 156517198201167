@use "src/shared/UI/styles/mixins";

$textSizes: 12, 14, 16, 18, 20, 24;
$textWeights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

.text {
  @each $textSize in $textSizes {
    &--#{$textSize} {
      @if $textSize == 12 { @include mixins.font-size-12; }
      @if $textSize == 14 { @include mixins.font-size-14; }
      @if $textSize == 16 { @include mixins.font-size-16; }
      @if $textSize == 18 { @include mixins.font-size-18; }
      @if $textSize == 20 { @include mixins.font-size-20; }
      @if $textSize == 24 { @include mixins.font-size-24; }
    }
  }

  @each $textWeight in $textWeights {
    &--#{$textWeight} {
      font-weight: $textWeight;
    }
  }
}
