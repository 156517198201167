@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";
@use "../../styles" as const;

.header {
  min-height: const.$header-min-height;
  background: const.$header-bg;
  padding: const.$header-padding;
  border-bottom: 1px solid var(--color-border);

  .container {
    width: 100%;
  }
}
