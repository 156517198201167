@use "src/shared/UI/styles/variables" as vars;

.title-container {
  position: relative;
}

.title {
  padding: 0 0 vars.$margin-xxs 0;
  margin-bottom: vars.$margin-xxl !important;
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-text);
  font-family: vars.$secondary-font-family;
}