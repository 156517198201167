@use "src/shared/UI/styles/variables";
@use "../base.module";

.title {
  @include base.base-text-item;
  padding-right: variables.$padding-xxl;
}

.icon > * {
  vertical-align: middle;
}