@use "src/shared/UI/styles/variables";

$header-min-height: 6.9rem;
$header-padding: 1.8rem 4.8rem;
$header-bg: var(--color-secondary-light-4);

$sidebar-width: 27.2rem;
$sidebar-min-height: 100vh;
$sidebar-padding: 2.7rem 2.9rem;
$sidebar-bg: var(--color-secondary-light-3);

$footer-bg: var(--color-primary-dark-3);
$footer-padding: 1.8rem 4.8rem;
$footer-height: 5.6rem;

$content-min-height: calc(100vh - #{$header-min-height});
$content-padding: 3.3rem 4.8rem;
$content-bg: var(--color-white);
