@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins" as global-mixins;
@use '../../../ui/styles/constants.module';

.control-panel-view {
  position: absolute;
  z-index: constants.$control-panel-z-index;
  display: flex;
  align-items: center;
  column-gap: vars.$margin-md;
  padding: vars.$padding-xs vars.$padding-md;
  border-radius: vars.$border-radius-md;
  background-color: var(--color-white);
  @include global-mixins.popup-box-shadow();

  &_vertical {
    flex-direction: column;
    row-gap: vars.$margin-md;
  }
}