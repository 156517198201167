@use "src/shared/UI/styles/variables" as vars;

$table-selection-padding: vars.$padding-md vars.$padding-xs;

$table-row-selected-bg: var(--color-primary-light-4);

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: $table-row-selected-bg;
}

table tr td.ant-table-selection-column,
table tr th.ant-table-selection-column {
  padding: $table-selection-padding;
}