@use "src/shared/UI/styles/variables" as vars;

$block-page-color: var(--color-secondary-light-2);

.container {
  position: relative;
  background-color: $block-page-color;
  flex: 1;
  padding: vars.$margin-lg;
  border-radius: vars.$border-radius-xl;
}

.loader {
  background-color: var(--color-white);
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  padding-top: 50vh;
}