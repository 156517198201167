.placeholder {
  border: 1px dashed var(--color-danger);
  background: var(--color--mark);
  cursor: pointer;
}

.placeholder[data-required] {
  border: 1px dashed var(--color-danger);
  background: var(--color-danger-light-2);
}

.placeholder[data-value] {
  border: 1px dashed var(--color-danger);
  background: var(--color-white);
}

.placeholder-final-document-view {
  .placeholder,
  .placeholder-block {
    border: none !important;
    background: white !important;
    color: inherit !important;
    cursor: initial !important;

    &:hover {
      background: var(--color-white);
    }
  }

  .block-edit {
    display: none;
  }

  p, span {
    background: white !important;
  }
}

