@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";

$table-filters-trigger-margin: -#{vars.$margin-xxs} -#{vars.$margin-xs} -#{vars.$margin-xxs} #{vars.$margin-xxs};
$table-filters-trigger-padding: 0 vars.$padding-xxs;
$table-filters-trigger-font-size: 1.2rem;

$table-filters-trigger-hover-color: var(--color-secondary-dark-1);
$table-filters-trigger-hover-bg: var(--color-primary-light-4);
$table-filters-trigger-active-color: var(--color-primary);
$table-filters-trigger-inactive-color: var(--color-secondary-light-1);

$table-filter-dropdown-width: 18rem;
$table-filter-dropdown-min-width: 18rem;
$table-filter-dropdown-border-radius: vars.$border-radius-sm;

$table-filter-dropdown-color: var(--color-text);
$table-filter-dropdown-bg-color: var(--color-white);

.ant-table-filter-trigger {
  margin: $table-filters-trigger-margin;
  padding: $table-filters-trigger-padding;
  color: $table-filters-trigger-inactive-color;
  font-size: $table-filters-trigger-font-size;

  &:hover {
    color: $table-filters-trigger-hover-color;
    background: $table-filters-trigger-hover-bg;
  }

  &.active {
    color: $table-filters-trigger-active-color;
  }
}

.ant-table-filter-dropdown {
  min-width: $table-filter-dropdown-min-width;
  border-radius: $table-filter-dropdown-border-radius;
  color: $table-filter-dropdown-color;
  background-color: $table-filter-dropdown-bg-color;
  @include mixins.font-size-14();
  @include mixins.distant-box-shadow();
}