$range-picker-active-bar: var(--color-primary);
$range-picker-dashed-border: var(--color-primary-dark-2);
$range-picker-selected-range-bg: var(--color-primary-light-4);
$range-picker-selected-range-bg-hover: var(--color-primary-light-2);
$range-picker-selected-range-bg-hover-start: var(--color-primary-light-3);
$range-picker-separator: var(--color-secondary);

.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
  border-left-color: $range-picker-dashed-border;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
  border-top-color: $range-picker-dashed-border;
  border-bottom-color: $range-picker-dashed-border;
}

.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
  border-right-color: $range-picker-dashed-border;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: $range-picker-selected-range-bg;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before {
  background: $range-picker-selected-range-bg-hover;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
  background: $range-picker-selected-range-bg-hover-start;
}

.ant-picker-separator,
.ant-picker-focused .ant-picker-separator {
  color: $range-picker-separator;
}

.ant-picker-range .ant-picker-active-bar {
  background: $range-picker-active-bar;
}