$table-row-odd-bg: var(--color-secondary-light-4);

.table--empty {
  :global(.ant-table-content) {
    min-height: initial;
  }
}

.table-row {
  &--odd {
    background: $table-row-odd-bg;
  }

  &--clickable {
    cursor: pointer;
  }
}