@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";

.dropdown-overlay {
  min-width: unset !important;
}

.dropdown-menu {
  padding: 1.8rem vars.$padding-md;
  max-width: unset;
  border-radius: vars.$border-radius-md;
  @include mixins.popup-box-shadow();
}

.dropdown-menu-item-wrapper {
  margin-top: 0;
  height: 2.4rem;
  line-height: 2.4rem;

  &:not(:last-child) {
    margin-bottom: vars.$margin-md;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:active {
    background-color: inherit !important;
  }
}

.dropdown-menu-item {
  line-height: 2.4rem !important;
}