@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";
@use "./constants";

$table-footer-border-radius: 0 0 constants.$table-border-radius constants.$table-border-radius;
$table-footer-padding: vars.$padding-md;
$table-footer-cell-padding: vars.$padding-md;
$table-small-footer-padding: vars.$padding-xs;
$table-small-footer-cell-padding: vars.$padding-xs;
$table-middle-footer-padding: vars.$padding-sm vars.$padding-xs;
$table-middle-footer-cell-padding: vars.$padding-sm vars.$padding-xs;

$table-footer-bg: var(--color-white);
$table-footer-color: var(--color-text);

.ant-table-footer {
  padding: $table-footer-padding;
  border-radius: $table-footer-border-radius;
  background: $table-footer-bg;
  color: $table-footer-color;
  @include mixins.font-size-14;
}

.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
  padding: $table-footer-cell-padding;
}

.ant-table.ant-table-small {
  .ant-table-footer {
    padding: $table-small-footer-padding;
  }
}

.ant-table.ant-table-small tfoot > tr > td,
.ant-table.ant-table-small tfoot > tr > th {
  padding: $table-small-footer-cell-padding;
}

.ant-table.ant-table-middle {
  .ant-table-footer {
    padding: $table-middle-footer-padding;
  }
}

.ant-table.ant-table-middle tfoot > tr > td,
.ant-table.ant-table-middle tfoot > tr > th {
  padding: $table-middle-footer-cell-padding;
}

.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid var(--color-border);
  border-top: 0;
}