@use "src/shared/UI/styles/variables" as vars;
@use "./constants";

$table-title-padding: vars.$padding-md;
$table-title-border-radius: constants.$table-border-radius constants.$table-border-radius 0 0;
$table-small-title-padding: vars.$padding-xs;
$table-middle-title-padding: vars.$padding-sm vars.$padding-xs;

$table-title-bg: var(--color-white);
$table-title-color: var(--color-text);

.ant-table-title {
  padding: $table-title-padding;
  border-radius: $table-title-border-radius;
  background: $table-title-bg;
  color: $table-title-color;
}

.ant-table.ant-table-small {
  .ant-table-title {
    padding: $table-small-title-padding;
  }
}

.ant-table.ant-table-middle {
  .ant-table-title {
    padding: $table-middle-title-padding;
  }
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid var(--color-border);
  border-bottom: 0;
}