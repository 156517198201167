@use "../../../../styles" as const;

.menu-container {
  overflow: hidden;
  width: 27.2rem;
}

.menu {
  box-shadow: none !important;
}

.sticky {
  &-container {
    height: calc(100% - const.$header-min-height);
    overflow: visible !important;
  }

  &-menu {
    position: sticky;
    top: 0;
    left: 0;
    overflow-y: auto;
    height: 100vh;
  }
}