@use "src/shared/UI/styles/variables" as vars;
@use "../../styles/mixins";

.btn-container {
  position: relative;
  padding: 1.4rem;
  background-color: var(--color-secondary-light-4);
  cursor: pointer;
  @include mixins.contour(0px, 0px, 0px, 0px);

  &:hover {
    background-color: var(--color-primary-light-3);
  }
}

.btn-label {
  color: var(--color-primary-dark-1);
  font-family: vars.$primary-font-family !important;
}