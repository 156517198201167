@use "src/shared/UI/styles/functions";

@mixin colorizeButton($colorScheme) {
  background-color: map-get($colorScheme, "default", "bg-color");
  color: map-get($colorScheme, "default", "color");
  border-color: map-get($colorScheme, "default", "border-color");
  :global(.app-button-icon-container) {
    svg path {
      transition: functions.transition(fill);
      fill: map-get($colorScheme, "default", "color");
    }
  }
  &:hover, &:focus {
    background-color: map-get($colorScheme, "hover", "bg-color");
    color: map-get($colorScheme, "hover", "color");
    border-color: map-get($colorScheme, "hover", "border-color");
    :global(.app-button-icon-container) {
      path {
        fill: map-get($colorScheme, "hover", "color");
      }
    }
  }
  &:active {
    background-color: map-get($colorScheme, "active", "bg-color");
    color: map-get($colorScheme, "active", "color");
    border-color: map-get($colorScheme, "active", "border-color");
    :global(.app-button-icon-container) {
      path {
        fill: map-get($colorScheme, "active", "color");
      }
    }
  }
  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
    background-color: map-get($colorScheme, "disabled", "bg-color");
    color: map-get($colorScheme, "disabled", "color");
    border-color: map-get($colorScheme, "disabled", "border-color");
    :global(.app-button-icon-container) {
      path {
        fill: map-get($colorScheme, "disabled", "color");
      }
    }
  }
}