@use "src/shared/UI/styles/mixins";

$linkColor: var(--color-text);
$linkHoverColor: var(--color-primary-light-2);
$linkActiveColor: var(--color-primary-dark-1);
$textSizes: 12, 14, 16, 18, 20, 24;


.link {
  color: $linkColor;
  &:hover {
    color: $linkHoverColor !important;
  }
  &:active {
    color: $linkActiveColor;
  }

  &, &--14 {
    @include mixins.font-size-14;
  }

  @each $textSize in $textSizes {
    &--#{$textSize} {
      @if $textSize == 12 { @include mixins.font-size-12; }
      @if $textSize == 16 { @include mixins.font-size-16; }
      @if $textSize == 18 { @include mixins.font-size-18; }
      @if $textSize == 20 { @include mixins.font-size-20; }
      @if $textSize == 24 { @include mixins.font-size-24; }
    }
  }
}

:global(.ant-menu-item) a.link:hover {
  color: $linkHoverColor;
}