@use "src/shared/UI/styles/variables" as vars;

.md-editor {
  min-width: 10rem;

  :global(.w-md-editor-content) {
    border: 1px solid var(--color-primary-dark-1);
    border-radius: vars.$border-radius-md;
  }

  :global(.w-md-editor-aree) {
    border-radius: 0;
  }

  :global(.w-md-editor-text-pre > code),
  :global(.w-md-editor-text-input) {
    line-height: 1.15 !important;
  }
}

.textarea-editor {
  min-width: 10rem;
  border: 1px solid var(--color-primary-dark-1);
  border-radius: vars.$border-radius-md;
  line-height: 1.15 !important;
}