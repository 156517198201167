.icon {
  cursor: pointer;

  path {
    stroke: var(--color-primary-dark-1);
  }

  &_size_24 {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }

  &_fill {
    path {
      fill: var(--color-primary-dark-1);
    }
  }
}