@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$form-item-label-color: var(--color-text);
$form-item-warning-color: var(--color-warning);
$form-item-mark-color: var(--color-danger);
$form-item-error-color: var(--color-danger);

.ant-form-item-label {

  &.ant-form-item-label {
    padding-bottom: variables.$padding-xs;
  }

  label {
    @include mixins.font-size-14;
    font-weight: 600;
    color: $form-item-label-color;
  }

  & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    position: absolute;
    right: -1.1rem;
    color: $form-item-mark-color;
    @include mixins.font-size-18;
    font-family: sans-serif;
  }
}

.ant-form-item-explain-warning {
  color: $form-item-warning-color;
  @include mixins.font-size-12;
  min-width: 12rem;
}

.ant-form-item-explain-error {
  color: $form-item-error-color;
  @include mixins.font-size-14;
  min-width: 12rem;
}
