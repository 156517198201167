@mixin _font-size($font-size, $line-height) {
  font-size: $font-size !important;
  line-height: $line-height !important;
}

@mixin font-size-38 {
  @include _font-size(3.8rem, 4.6rem);
}

@mixin font-size-32 {
  @include _font-size(3.2rem, 4rem);
}

@mixin font-size-30 {
  @include _font-size(3rem, 3.6rem);
}

@mixin font-size-24 {
  @include _font-size(2.4rem, 3rem);
}

@mixin font-size-20 {
  @include _font-size(2rem, 2.8rem);
}

@mixin font-size-18 {
  @include _font-size(1.8rem, 2.4rem);
}

@mixin font-size-16 {
  @include _font-size(1.6rem, 2.2rem);
}

@mixin font-size-14 {
  @include _font-size(1.4rem, 2rem);
}

@mixin font-size-12 {
  @include _font-size(1.2rem, 1.8rem);
}