@use "src/shared/UI/styles/variables" as vars;

$table-sorter-margin-left: 1.5rem;
$table-sorter-font-size: 1.5rem;

$table-sorted-column-bg: inherit;
$table-sorter-cell-bg: var(--color-primary-light-4);
$table-sorter-cell-hover-bg: var(--color-primary-light-4);
$table-sorter-active-color: var(--color-secondary-dark-1);
$table-sorter-hover-color: var(--color-secondary);
$table-sorter-inactive-color: var(--color-secondary-light-1);

td.ant-table-column-sort {
  background: $table-sorted-column-bg;
}

.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover,
.ant-table-thead th.ant-table-column-sort {
  background: $table-sorter-cell-bg;

  &:before {
    background-color: var(--color-border) !important;
  }
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: $table-sorter-cell-hover-bg;

  &:before {
    background-color: var(--color-border) !important;
  }
}

.ant-table-column-sorters {
  justify-content: center;
  align-items: center;

  &:hover {
    .ant-table-column-sorter {
      color: $table-sorter-hover-color;
    }
  }
}

.ant-table-column-title {
  flex: 0 1 auto;
}

.ant-table-column-sorter {
  margin-left: $table-sorter-margin-left;
  color: $table-sorter-inactive-color;
}

.ant-table-column-sorter-down,
.ant-table-column-sorter-up {
  font-size: $table-sorter-font-size;
}

.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: $table-sorter-active-color;
}