@use "src/shared/UI/styles/variables" as vars;

.table {
  width: 100%;
  border: 1px solid var(--color-black);

  & th, & td {
    border: 1px solid var(--color-black);
    padding: vars.$padding-sm;
  }
}