@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

.form {
  & :global {
    .ant-form-item-explain-error {
      @include mixins.font-size-14;
    }

    .ant-form-item-has-error {
      .ant-radio-wrapper,
      .ant-radio .ant-radio-inner {
        border-color: var(--color-danger) !important;
      }
    }
  }
}

.radio-group-card {
  & :global {
    .ant-radio-wrapper {
      padding: variables.$padding-lg variables.$padding-md;
      border: 1px solid var(--color-border);
      border-radius: variables.$border-radius-md;
      transition: .3s all;
      @include mixins.font-size-18();
      font-weight: 400;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: variables.$margin-xl !important;
      }

      &:hover {
        border-color: var(--color-primary-light-1);
      }

      & :global(.ant-radio + span) {
        padding-left: variables.$padding-md !important;
      }
    }
  }
}