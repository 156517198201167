.tooltip-overlay {
  max-width: 40rem;
}

.icon-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.4rem;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--color-secondary-light-3);
  }
}

.icon {
  display: block;
  width: 1.4rem !important;
  height: 1.4rem !important;
}