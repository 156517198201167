.menu {
  &:global(.ant-menu) {
    background: rgba(0, 0, 0, 0);
  }

  & :global(.ant-menu-item),
  & :global(.ant-menu-submenu-title),
  & :global(.ant-menu-sub){
    background: rgba(0, 0, 0, 0);
  }
}
