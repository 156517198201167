.blocker {
  $rootClass: &;
  display: inline-block;
  position: relative;

  &_blocking {
    user-select: none;

    & > :not(:first-child):not(:last-child) {
      pointer-events: none;
    }

    &#{$rootClass}_visible {
      cursor: wait;

      &#{$rootClass}_opacity_min {
        & > :not(:first-child):not(:last-child) {
          opacity: 1;
        }
      }

      &#{$rootClass}_opacity_medium {
        & > :not(:first-child):not(:last-child) {
          opacity: 0.6;
        }
      }

      &#{$rootClass}_opacity_max {
        & > :not(:first-child):not(:last-child) {
          opacity: 0;
        }
      }
    }
  }
}