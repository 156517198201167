.icon-wrapper {
  border-radius: 50%;
  padding: 0.4rem;

  &:hover {
    background-color: var(--color-secondary-light-3);
    cursor: pointer;
  }
}

.icon {
  display: block;
  width: 1.6rem !important;
  height: 1.6rem !important;
}