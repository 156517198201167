@use "src/shared/UI/styles/variables";

$dropdown-menu-item-group-title-padding: variables.$padding-xs variables.$padding-sm;
$dropdown-menu-item-group-list-padding: 0 0 0 variables.$padding-sm;

$dropdown-menu-item-group-title-color: var(--color-text-secondary);

.ant-dropdown-menu-item-group-title {
  padding: $dropdown-menu-item-group-title-padding;
  color: $dropdown-menu-item-group-title-color;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 !important;
  padding: $dropdown-menu-item-group-list-padding !important;
}