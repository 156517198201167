.placeholder-block {
  border: 1px dashed var(--color-danger);
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1.8rem !important;
  position: relative;
}

.block-edit {
  top: 0;
  right: 0;
  position: absolute;
  background: var(--color-success);
  cursor: pointer;
}

.with-margin-bottom {
  margin-bottom: 2rem !important;

  &_small {
    margin-bottom: 1rem !important;
  }
}

.with-text-align-right {
  text-align: right;
}

#considered, #agreed, #approved {
  padding-right: 1rem !important;
  width: 33.33%;
  padding-left: 1rem !important;
}
