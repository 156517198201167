@use "src/shared/UI/styles/colors/palette";

$scrollbar-track-bg: rgba(palette.$gray-6, .25);
$scrollbar-thumb-bg: rgba(palette.$gray-6, .6);
$scrollbar-dark-thumb-bg: rgba(palette.$gray-1, .6);

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $scrollbar-track-bg;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $scrollbar-thumb-bg;
}

* {
  scrollbar-color: $scrollbar-thumb-bg $scrollbar-track-bg;
  scrollbar-width: thin;
}
