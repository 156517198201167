@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/mixins/breakpoints";

.content {
  height: 100%;
  background-color: var(--color-white);
  border-radius: vars.$border-radius-md * 2;
  @include mixins.distant-box-shadow;

  @include breakpoints.mobile-screen-size {
    border-radius: 0;
    box-shadow: none;
  }
}

.body {
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
}