$select-dropdown-bg-color: var(--color-white);
$select-dropdown-item-disabled-text-color: var(--color-main-disabled);
$select-item-text-color: var(--color-text);
$select-item-option-hover-bg-color: var(--color-secondary);
$select-item-option-selected-bg-color: var(--color-primary-light-4);

.ant-select-dropdown {
  background: $select-dropdown-bg-color;
}

.ant-select-item {
  color: $select-item-text-color;
}

.ant-select-item-option-disabled {
  color: $select-dropdown-item-disabled-text-color;
}

.ant-:not(.ant-select-item-option-disabled) {
  background: $select-item-option-hover-bg-color;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $select-item-text-color;
  background: $select-item-option-selected-bg-color;
}