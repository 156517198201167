@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

$vertical-menu-padding: 0;
$vertical-menu-border-radius: variables.$border-radius-sm;
$vertical-menu-max-width: 20rem;
$vertical-menu-sub-min-width: 10rem;
$vertical-menu-sub-max-width: 20rem;
$vertical-menu-sub-padding: 0;
$vertical-menu-sub-border-radius: variables.$border-radius-sm;

.ant-menu-vertical {
  padding: $vertical-menu-padding;
  border-radius: $vertical-menu-border-radius;
  width: max-content;
  max-width: $vertical-menu-max-width;
  border-right: none;
  @include mixins.box-shadow;
}

.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
  min-width: $vertical-menu-sub-min-width;
  max-width: $vertical-menu-sub-max-width;
  padding: $vertical-menu-sub-padding;
}

.ant-menu-submenu > .ant-menu-vertical  {
  border-radius: $vertical-menu-sub-border-radius;
}