@use "src/shared/UI/styles/mixins";

$typography-types: (
  "secondary": var(--color-text-secondary),
  "success": var(--color-success),
  "warning": var(--color-warning),
  "danger": var(--color-danger),
);
$typography-text-color: var(--color-text);
$typography-text-disabled-color: var(--color-main-disabled);
$typography-mark-color: var(--color--mark);

$typography-text-margin-bottom: 0;
$typography-title-margin-bottom: 0;
$typography-title-font-weight: 700;

.ant-typography {
  $rootClass: &;
  color: $typography-text-color;

  @each $typography-type, $typography-color in $typography-types {
    &#{$rootClass}-#{$typography-type} {
      color: $typography-color;
    }
  }

  &#{$rootClass}-disabled {
    color: $typography-text-disabled-color;
  }
}

.ant-typography p,
div.ant-typography {
  margin-bottom: $typography-text-margin-bottom;
}

@mixin title() {
  margin-bottom: $typography-title-margin-bottom;
  color: $typography-text-color;
  font-weight: $typography-title-font-weight;
}

@each $level in "h1", "h2", "h3", "h4", "h5" {
  .ant-typography #{$level},
  div.ant-typography-#{$level},
  div.ant-typography-#{$level} > textarea,
  #{$level}.ant-typography {
    @include title();
    @if $level == "h1" { @include mixins.font-size-32; }
    @if $level == "h2" { @include mixins.font-size-24; }
    @if $level == "h3" { @include mixins.font-size-20; }
    @if $level == "h4" { @include mixins.font-size-16; }
    @if $level == "h5" { @include mixins.font-size-12; }
  }
}

.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 0;
}

@each $prev in "h1", "h2", "h3", "h4", "h5", "div", "p", "ul", "li" {
  @each $next in "h1", "h2", "h3", "h4", "h5" {
    .ant-typography #{$prev} + #{$next} {
      margin-top: 0;
    }
  }
}

.ant-typography mark {
  background-color: $typography-mark-color;
}

.ant-typography-disabled mark {
  color: $typography-text-disabled-color;
}
