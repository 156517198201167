@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/functions";
@use "src/shared/UI/styles/mixins";

@mixin base-menu-item() {
  padding: 0 variables.$padding-sm;
}

@mixin base-text-item() {
  @include base-menu-item;
  height: 100%;
  line-height: 3rem !important;
  transition: functions.transition(color);

  >:not(:first-child) {
    margin-left: variables.$margin-xs !important;
  }

  &:not(:global(.ant-typography-disabled)):hover {
    color: var(--color-primary);

    @include mixins.mobile-screen-size {
      color: var(--color-text);
    }
  }
}
