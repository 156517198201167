@use "src/shared/UI/styles/variables/layout";

$radio-margin: layout.$margin-xs;

.horizontal {
  &--radio {
    & > * {
      margin: 0 $radio-margin 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.vertical {
  &--radio,
  &--button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &--radio {
    & > * {
      margin: 0 0 $radio-margin 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &--button {
    :global {
      .ant-radio-button-wrapper {
        &:before {
          position: absolute;
          top: -1px;
          left: -1px;
          display: block;
          box-sizing: content-box;
          width: 1px;
          height: 100%;
          padding: 1px 0;
          transition: background-color 0.3s;
          content: "";
        }

        &:first-child {
          border-radius: 0;
          border-left: 0;
        }
        &:last-child {
          border-radius: 0;
        }
        &:first-child:last-child {
          border-radius: 0;
        }
      }
    }
  }
}