@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$label-color: var(--color-text);
$mark-color: var(--color-danger);

.label {
  color: $label-color;
  padding-bottom: variables.$padding-xs;
  align-self: flex-start;
  position: relative;
  @include mixins.font-size-14;
  font-weight: 600;

  &.required::before{
    display: inline-block;
    content: "*";
    position: absolute;
    right: -1.1rem;
    color: $mark-color;
    @include mixins.font-size-18;
    margin-right: variables.$margin-xxs;
    font-family: sans-serif;
    line-height: 1 !important;
  }
}
