@use "src/shared/UI/styles/variables" as vars;

$block-page-color: var(--color-secondary-light-2);

.container {
  position: relative;
  background-color: $block-page-color;
  flex: 1;
  padding: vars.$margin-lg;
  border-radius: vars.$border-radius-xl;
}

.observerView {
  & * {
    background: var(--color-white) !important;
  }

  :global(.placeholder) {
    border: none;
  }

  :global(.placeholder-block) {
    border: none;
  }

  :global(.block-edit) {
    display: none;
  }
}

.diffContent {
  :global(.diff-html-added) {
    background-color: lightgreen !important;
  }

  :global(.diff-html-removed) {
    background-color: lightcoral !important;
    text-decoration: line-through !important;
  }
}

.diffContent {
  :global(.diff-html-added).animation {
    animation: blink .7s step-start 0s 3;
    border: 1px dashed red;
  }
}

@keyframes blink {
  50% {
    opacity: 0.0;
  }
}
