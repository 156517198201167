$inline-menu-item-height: 3rem;
$inline-menu-item-line-height: 3rem;
$inline-menu-item-padding: 0;
$inline-menu-item-margin: 0.3rem;

$inline-sub-menu-item-bg-color: var(--color-secondary-light);
$inline-sub-menu-item-active-color: var(--color-secondary-light-3);

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  height: $inline-menu-item-height;
  line-height: $inline-menu-item-line-height;
  padding: $inline-menu-item-padding !important;
  margin-top: $inline-menu-item-margin;
  margin-bottom: $inline-menu-item-margin;
}

.ant-menu-inline > .ant-menu-item,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: $inline-menu-item-height;
  line-height: $inline-menu-item-line-height;
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-submenu:not(:last-child) {
  margin-bottom: calc(#{$inline-menu-item-margin} * 2);
}

.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0;
}

.ant-menu-inline.ant-menu-sub .ant-menu-item,
.ant-menu-inline.ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title {
  height: $inline-menu-item-height;
  line-height: $inline-menu-item-line-height;
  background: $inline-sub-menu-item-bg-color;
}

.ant-menu-inline.ant-menu-sub .ant-menu-item:not(.ant-menu-item-disabled):active,
.ant-menu-inline.ant-menu-sub .ant-menu-submenu:not(.ant-menu-submenu-disabled) .ant-menu-submenu-title:active {
  background: $inline-sub-menu-item-active-color;
}