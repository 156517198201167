@use "src/shared/UI/styles/variables";

$tooltip-bg: var(--color-secondary-dark-3);
$tooltip-text-color: var(--color-white);
$tooltip-box-shadow: none;

$tooltip-min-height: 3rem;
$tooltip-min-width: 3rem;
$tooltip-border-radius: variables.$border-radius-sm;
$tooltip-padding: variables.$padding-xxs variables.$padding-xs;
$tooltip-font-size: 1.4rem;

.ant-tooltip {
  font-size: $tooltip-font-size;
}
.ant-tooltip-inner {
  background-color: $tooltip-bg;
  color: $tooltip-text-color;
  padding: $tooltip-padding;
  min-width: $tooltip-min-width;
  min-height: $tooltip-min-height;
  border-radius: $tooltip-border-radius;
  box-shadow: $tooltip-box-shadow;
}
.ant-tooltip-arrow-content::before {
  background: $tooltip-bg;
}