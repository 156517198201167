@use "../mixins";

$button-text-colors: (
  "primary": (
    "default": (
      "color": var(--color-primary),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "hover": (
      "color": var(--color-primary-light-2),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "active": (
      "color": var(--color-primary-dark-1),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": transparent,
      "bg-color": transparent,
    ),
  ),
  "success": (
    "default": (
      "color": var(--color-success),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "hover": (
      "color": var(--color-success-light-2),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "active": (
      "color": var(--color-success-dark-1),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": transparent,
      "bg-color": transparent,
    ),
  ),
  "warning": (
    "default": (
      "color": var(--color-warning),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "hover": (
      "color": var(--color-warning-light-2),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "active": (
      "color": var(--color-warning-dark-1),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": transparent,
      "bg-color": transparent,
    ),
  ),
  "danger": (
    "default": (
      "color": var(--color-danger),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "hover": (
      "color": var(--color-danger-light-2),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "active": (
      "color": var(--color-danger-dark-1),
      "border-color": transparent,
      "bg-color": transparent,
    ),
    "disabled": (
      "color": var(--color-main-disabled),
      "border-color": transparent,
      "bg-color": transparent,
    ),
  ),
);
$button-text-default-color: "primary";

.type-text {
  $defaultColorScheme: map-get($button-text-colors, $button-text-default-color);
  $colorsWithoutDefault: map-remove($button-text-colors, $button-text-default-color);

  @include mixins.colorizeButton($defaultColorScheme);

  @each $colorName, $colorScheme in $colorsWithoutDefault {
    &.color-#{$colorName} {
      @include mixins.colorizeButton($colorScheme);
    }
  }
}