@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/mixins/breakpoints";
@use "../../styles/constants.module" as const;

$dialog-max-width: calc(100vw - #{const.$dialog-side-indent} * 2);
$dialog-max-height: calc(100vh - #{const.$dialog-side-indent} * 2);
$dialog-sizes: (
  "auto": ("width": 66rem, "height": auto),
  "small": ("width": 40rem, "height": 30rem),
  "medium": ("width": 57rem, "height": 80vh),
  "large": ("width": 111.2rem, "height": 80vh),
  "fullscreen": ("width": $dialog-max-width, "height": $dialog-max-height),
);

.dialog {
  position: relative;
  max-width: $dialog-max-width;
  max-height: $dialog-max-height;
  color: var(--color-text);
  pointer-events: auto;
  @include mixins.font-size-14;
  font-feature-settings: "tnum"; // as in antd
  font-variant: tabular-nums; // as in antd
  will-change: opacity;

  &_size {
    @each $size-name, $size-values in $dialog-sizes {
      &_#{$size-name} {
        width: map-get($size-values, "width");
        height: map-get($size-values, "height");
      }
    }
  }

  &_visible {
    &_init {
      opacity: 0;
    }
    &_active {
      opacity: 1;
      transition-property: opacity;
      transition-duration: const.$dialog-transition-duration;
      transition-timing-function: ease-in;
    }
    &_done {
      opacity: 1;
    }
  }

  &_hidden, &_destroyed {
    &_init {
      opacity: 1;
    }
    &_active {
      opacity: 0;
      transition-property: opacity;
      transition-duration: const.$dialog-transition-duration;
      transition-timing-function: ease-out;
    }
    &_done {
      opacity: 0;
    }
  }

  @include breakpoints.mobile-screen-size {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}