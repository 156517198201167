@use "src/shared/UI/styles/variables";

$danger-color: var(--color-danger);

.container {
  border: 2px solid $danger-color;
  border-radius: variables.$border-radius-md;
  padding: variables.$padding-md;
  width: 100%;
}

.details {
  white-space: pre-wrap;
}

.header {
  text-align: center;
  color: $danger-color;
}

.subheader {
  margin: variables.$margin-md;
  color: $danger-color;
}

.try-again {
  margin: variables.$margin-md;
}