@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

$element-text-color: var(--color-secondary-light-1);
$element-text-color-hover: var(--color-primary);
$last-element-text-color: var(--color-text);

.item {
  display: flex;
  align-items: center;

  & .title {
    @include mixins.font-size-18;
  }

  & .title,
  & .prefix {
    color: $element-text-color !important;
  }

  &:hover .title,
  &:hover .prefix {
    color: $element-text-color-hover !important;
  }
}

.last {
  cursor: default;
  color: $last-element-text-color;
  @include mixins.font-size-18;
}

.prefix, .title {
  display: flex;
  align-items: center;
  margin-bottom: variables.$margin-xxxs;
}

.more {
  color: $element-text-color;
  line-height: 1rem !important;

  &:hover {
    color: $element-text-color-hover;
  }
}