@use "../variables";

@mixin desktop-screen-size {
  @media (min-width: variables.$lg) {
    @content;
  }
}

@mixin laptop-screen-size {
  @media (min-width: variables.$md) and (max-width: variables.$lg - 1) {
    @content;
  }
}

@mixin tablet-screen-size {
  @media (min-width: variables.$sm) and (max-width: variables.$md - 1) {
    @content;
  }
}

@mixin mobile-screen-size {
  @media (max-width: variables.$sm - 1) {
    @content;
  }
}

