@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/functions";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  transition: functions.transition(all);
  transition-property: background-color, color, border-left-color, border-right-color, border-bottom-color, border-top-color;

  >:not(:first-child) {
    margin-left: variables.$margin-xs;
  }

  &[disabled] {
    cursor: auto;
  }
  &.loading {
    cursor: auto;
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global(.app-button-icon-container) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}