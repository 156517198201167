@use "../base.module";

.link {
  &:hover .title:not(:global(.ant-typography-disabled)) {
    color: var(--color-primary);
  }
}

.title {
  @include base.base-text-item;
  display: flex;
  align-items: center;
}

.icon > * {
  display: flex;
  align-items: center;
}
