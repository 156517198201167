@use "src/shared/UI/styles/colors/palette";
@use "src/shared/UI/styles/variables" as vars;
@use "../../styles/constants.module" as const;

.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: vars.$z-index-modal;
  background-color: rgba(palette.$gray-6, .45);
  will-change: opacity;

  &_transparent {
    background-color: transparent;
  }

  &_visible {
    &_init {
      opacity: 0;
    }
    &_active {
      opacity: 1;
      transition-property: opacity;
      transition-duration: const.$mask-transition-duration;
      transition-timing-function: ease-in;
    }
    &_done {
      opacity: 1;
    }
  }

  &_hidden, &_destroyed {
    &_init {
      opacity: 1;
    }
    &_active {
      opacity: 0;
      transition-property: opacity;
      transition-duration: const.$mask-transition-duration;
      transition-timing-function: ease-out;
    }
    &_done {
      opacity: 0;
    }
  }
}