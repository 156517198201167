@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.loading-outlined {
  animation: loadingCircle 1s infinite linear;
  path {
    fill: var(--color-primary);
  }
}