@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";
@use "../base.module";

.checkbox {
  @include base.base-menu-item;
  padding-right: 0;
  width: 100%;

  :global(.ant-checkbox) + span {
    flex: 1;
    padding: 0;
    @include mixins.ellipsis;

    > :global(.ant-typography) {
      padding: 0 variables.$padding-xs;

      > :not(:first-child) {
        margin-left: variables.$margin-xs;
      }
    }
  }
}

.icon > * {
  vertical-align: middle;
}