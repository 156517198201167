@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$inline-sub-menu-bg-color: var(--color-secondary-light-4);

$inline-menu-padding: 0;
$inline-menu-max-width: 100%;
$inline-menu-submenu-padding: 0;

.ant-menu-inline {
  padding: $inline-menu-padding;
  border-right: none;
  max-width: $inline-menu-max-width;
  @include mixins.box-shadow;
}

.ant-menu-inline.ant-menu-sub {
  box-shadow: none !important;
  background: $inline-sub-menu-bg-color;
}

.ant-menu-submenu-inline .ant-menu-inline.ant-menu-sub {
  padding: $inline-menu-submenu-padding;
}