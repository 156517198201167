@use "src/shared/UI/styles/colors/palette";

$skeleton-bg-color-light: rgba(palette.$gray-4, .3);
$skeleton-bg-color-dark: rgba(palette.$gray-6, .34);
$animation-time: 1.4s;

.skeleton {
  display: inline-block;
  width: 100%;
  height: 1rem;
  position: relative;
  overflow: hidden;
  background: $skeleton-bg-color-light;

  &--animation {
    background: linear-gradient(
        90deg,
        $skeleton-bg-color-light 25%,
        $skeleton-bg-color-dark 37%,
        $skeleton-bg-color-light 63%
    );
    background-size: 400% 100%;
    animation: skeleton-animation $animation-time ease infinite;
  }
}

@keyframes skeleton-animation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}