@use "src/shared/UI/styles/variables/layout";
@use "src/shared/UI/styles/variables";

$radio-btn-height: 3.2rem;
$radio-btn-padding: layout.$padding-md;
$radio-btn-border-radius: layout.$border-radius-sm;

$radio-btn-color: var(--color-text);
$radio-btn-bg-color: var(--color-white);
$radio-btn-border-color: var(--color-border);

$radio-btn-hover-color: var(--color-primary);
$radio-btn-hover-bg-color: var(--color-white);
$radio-btn-hover-border-color: var(--color-primary);

$radio-btn-checked-color: var(--color-primary);
$radio-btn-checked-bg-color: var(--color-white);
$radio-btn-checked-border-color: var(--color-primary);

$radio-btn-checked-disabled-color: var(--color-main-disabled);
$radio-btn-checked-disabled-bg-color: var(--color-additional-disabled);
$radio-btn-checked-disabled-border-color: var(--color-main-disabled);

$radio-btn-disabled-color: var(--color-main-disabled);
$radio-btn-disabled-bg-color: var(--color-additional-disabled);
$radio-btn-disabled-border-color: var(--color-main-disabled);

.ant-radio-button-wrapper {
  height: $radio-btn-height;
  padding: 0 $radio-btn-padding;
  line-height: calc(#{$radio-btn-height} - 2px);
  box-shadow: none !important;

  background-color: $radio-btn-bg-color !important;
  border-color: $radio-btn-border-color !important;
  color: $radio-btn-color !important;

  &:before {
    background-color: $radio-btn-border-color !important;

    &:hover {
      background-color: $radio-btn-hover-border-color !important;
    }
  }

  &:not(.ant-radio-button-wrapper-disabled):not(.ant-radio-button-wrapper-checked) {
    &:hover {
      background-color: $radio-btn-hover-bg-color !important;
      border-color: $radio-btn-hover-border-color !important;
      color: $radio-btn-hover-color !important;
      z-index: 1;
    }

    &:hover:before {
      background-color: $radio-btn-hover-border-color !important;
    }
  }

  &:first-child {
    border-radius: $radio-btn-border-radius 0 0 $radio-btn-border-radius;
  }
  &:last-child {
    border-radius: 0 $radio-btn-border-radius $radio-btn-border-radius 0;
  }
  &:first-child:last-child {
    border-radius: $radio-btn-border-radius;
  }
}

.ant-radio-button-wrapper-checked {
  z-index: variables.$z-index-radio-button-checked !important;
}

.ant-radio-button-wrapper-checked,
.ant-radio-button-checked,
.ant-radio-button-input:focus {
  background-color: $radio-btn-checked-bg-color !important;
  border-color: $radio-btn-checked-border-color !important;
  color: $radio-btn-checked-color !important;
}

.ant-radio-button-wrapper-checked:before {
  background-color: $radio-btn-checked-border-color !important;
}

.ant-radio-button-wrapper-disabled {
  background-color: $radio-btn-disabled-bg-color !important;
  border-color: $radio-btn-disabled-border-color !important;
  color: $radio-btn-disabled-color !important;

  &:before {
    background-color: $radio-btn-disabled-border-color !important;
  }

  &.ant-radio-button-wrapper-checked,
  .ant-radio-button-checked,
  .ant-radio-button-input:focus  {
    background-color: $radio-btn-checked-disabled-bg-color !important;
    border-color: $radio-btn-checked-disabled-border-color !important;
    color: $radio-btn-checked-disabled-color !important;
  }

  &.ant-radio-button-wrapper-checked:before {
    background-color: $radio-btn-checked-disabled-border-color !important;
  }
}