@use "src/shared/UI/styles/functions";

.icon {
  position: absolute;
  top: 50%;
  transition: functions.transition(transform);
}

:global(.ant-menu-submenu-inline) {
  .icon {
    right: 1.6rem;
    transform: translateY(-50%);

    &_open {
      transform: rotate(180deg) translateY(50%);
    }
  }
}

:global(.ant-menu-submenu-vertical) {
  .icon {
    right: 1rem;
    transform: rotate(-90deg) translateX(25%);

    &_open {
      transform: rotate(90deg) translateX(-25%);
    }
  }
}