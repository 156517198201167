@use "src/shared/UI/styles/mixins";

$menu-submenu-arrow-color: var(--color-text);
$menu-submenu-arrow-hover-color: var(--color-primary);
$menu-submenu-arrow-disabled-color: var(--color-text-secondary);

.ant-menu-submenu-arrow, .ant-menu-submenu-expand-icon {
  color: $menu-submenu-arrow-color;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
  color: $menu-submenu-arrow-hover-color;

  @include mixins.mobile-screen-size {
    color: var(--color-text);
  }
}

.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: $menu-submenu-arrow-disabled-color !important;
}