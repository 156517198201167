@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$input-bg: var(--color-white);
$input-border: var(--color-border);
$input-border-hover: var(--color-primary-dark-1);
$input-border-focus: var(--color-primary-dark-1);
$input-error-bg: var(--color-white);
$input-error-border: var(--color-danger);
$input-warning-bg: var(--color-white);
$input-warning-border: var(--color-warning);
$input-clear-bg: var(--color-secondary-dark-2);
$input-clear-bg-hover: var(--color-secondary);
$input-disabled-text-color: var(--color-main-disabled);
$input-disabled-bg-color: var(--color-additional-disabled);
$input-disabled-border-color: var(--color-additional-disabled);
$input-suffix-color: var(--color-main-disabled);
$input-prefix-color: var(--color-main-disabled);
$input-text-color: var(--color-text);
$input-placeholder-color: var(--color-secondary);

.ant-input {
  width: 100%;
  height: 4rem;
  color: $input-text-color;
  border-radius: variables.$border-radius-md;
  border-color: $input-border;
  background-color: $input-bg;
  @include mixins.font-size-16;
  &:hover {
    border-color: $input-border-hover;
  }
  &::placeholder {
    color: $input-placeholder-color;
  }
}

.ant-input-affix-wrapper {
  color: $input-text-color;
  background-color: $input-bg;
  border-color: $input-border;
  .ant-input {
    height: 3rem;
  }
}

.ant-input-focused, .ant-input:focus {
  box-shadow: none;
  border-color: $input-border-focus;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: $input-error-bg;
  border-color: $input-error-border;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  background-color: $input-error-bg;
  border-color: $input-error-border;
  box-shadow: none;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: $input-warning-bg;
  border-color: $input-warning-border;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  background-color: $input-warning-bg;
  border-color: $input-warning-border;
  box-shadow: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $input-border-hover;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
  border-color: $input-border-focus;
  box-shadow: none;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: $input-error-bg;
  border-color: $input-error-border;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus {
  background: $input-error-bg;
  border-color: $input-error-border;
  box-shadow: none;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: $input-warning-bg;
  border-color: $input-warning-border;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus {
  background: $input-warning-bg;
  border-color: $input-warning-border;
  box-shadow: none;
}

.ant-input[disabled] {
  color: $input-disabled-text-color;
  border-color: $input-disabled-border-color;
  background-color: $input-disabled-bg-color;
  &:hover {
    border-color: $input-disabled-border-color;
  }
}

.ant-input-affix-wrapper-disabled {
  color: $input-disabled-text-color;
  border-color: $input-disabled-border-color;
  background-color: $input-disabled-bg-color;
  &:hover {
    border-color: $input-disabled-border-color;
  }
}

.ant-input-clear-icon, .anticon.ant-input-clear-icon {
  color: $input-clear-bg;
}

.ant-input-clear-icon:hover, .anticon.ant-input-clear-icon:hover {
  color: $input-clear-bg-hover;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled .ant-input-prefix,
.ant-input-affix-wrapper.ant-input-affix-wrapper[disabled] .ant-input-prefix,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless) .ant-input-prefix {
  color: $input-prefix-color;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled .ant-input-suffix,
.ant-input-affix-wrapper.ant-input-affix-wrapper[disabled] .ant-input-suffix,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless) .ant-input-suffix {
  color: $input-suffix-color;
}

.ant-input-borderless.ant-input-disabled,
.ant-input-borderless.ant-input[disabled],
.ant-input-borderless:hover.ant-input-disabled,
.ant-input-borderless:hover.ant-input[disabled],
.ant-input-borderless:focus.ant-input-disabled,
.ant-input-borderless:focus.ant-input[disabled],
.ant-input-borderless-focused.ant-input-disabled,
.ant-input-borderless-focused.ant-input[disabled] {
  background-color: $input-disabled-bg-color;
}

.ant-input-affix-wrapper-borderless.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper-borderless.ant-input-affix-wrapper[disabled],
.ant-input-affix-wrapper-borderless:hover.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper-borderless:hover.ant-input-affix-wrapper[disabled],
.ant-input-affix-wrapper-borderless:focus.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper-borderless:focus.ant-input-affix-wrapper[disabled],
.ant-input-affix-wrapper-borderless-focused.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper-borderless-focused.ant-input-affix-wrapper[disabled] {
  background-color: $input-disabled-bg-color;
}