$explain-height: 2rem;

.item {
  margin-bottom: $explain-height;
  transition: none !important;
  max-width: 100%;

  &:global(.ant-form-item-with-help) {
    & :global(.ant-form-item-explain) {
      height: auto;
      min-height: $explain-height;
    }
  }

  & :global(.ant-form-item-explain) {
    transition: none !important;
    height: 0;
    min-height: 0;
  }

  &.without-explain:not(.marginless):global(.ant-form-item-with-help) {
    margin-bottom: $explain-height;
  }

  &.marginless {
    margin-bottom: 0;
  }

  &.without-explain {
    :global(.ant-form-item-explain) {
      display: none;
    }
  }
}


