@use "src/shared/UI/styles/mixins";
@use "src/shared/UI/styles/variables";

$select-arrow-color: var(--color-secondary-dark-1);
$select-arrow-focused-color: var(--color-black);
$select-arrow-disabled-color: var(--color-secondary);
$select-bg-color: var(--color-white);
$select-border-color: var(--color-secondary-light-1);
$select-border-error-color: var(--color-danger);
$select-border-focus-color: var(--color-primary);
$select-border-hover-color: var(--color-primary);
$select-border-warning-color: var(--color-warning);
$select-clear-color: var(--color-secondary);
$select-clear-hover-color: var(--color-secondary-light-1);
$select-disabled-bg-color: var(--color-additional-disabled);
$select-disabled-border-color: var(--color-additional-disabled);
$select-disabled-text-color: var(--color-main-disabled);
$select-text-color: var(--color-text);
$select-placeholder-color: var(--color-secondary);

.ant-select {
  width: 100%;
  height: 4rem;
  color: $select-text-color;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: $select-bg-color;
  border-color: $select-border-color;
  border-radius: variables.$border-radius-md;
  height: 100%;
  
  .ant-select-selection-search-input {
    height: 100%;
  }
  .ant-select-selection-placeholder, .ant-select-selection-item {
    font-size: 1.6rem;
    line-height: 3.8rem;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $select-border-hover-color;
}

.ant-select-selection-placeholder {
  color: $select-placeholder-color;
}

.ant-select-arrow {
  path {
    fill: $select-arrow-color;
  }
}

.ant-select-disabled {
  path {
    fill: $select-arrow-disabled-color;
  }
}

.ant-select-status-success {
  path {
    fill: $select-arrow-focused-color;
  }
}

.ant-select-open {
  .ant-select-arrow {
    transform: rotate(180deg);
  }
}

.ant-select-clear {
  color: $select-clear-color;
  &:hover {
    color: $select-clear-hover-color;
  }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: $select-disabled-text-color;
  background: $select-disabled-bg-color;
  border-color: $select-disabled-border-color;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border-color: $select-border-focus-color;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector {
  box-shadow: none;
  border-color: $select-border-warning-color !important;
  background-color: $select-bg-color;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector {
  box-shadow: none;
  border-color: $select-border-error-color !important;
  background-color: $select-bg-color;
}