@use "src/shared/UI/styles/mixins";

$popover-text-color: var(--color-text);
$popover-title-color: var(--color-text);
$popover-inner-color: var(--color-white);
$popover-separator-color: var(--color-secondary-light-3);
$popover-background-color: var(--color-white);

.ant-popover {
  color: $popover-text-color;

  .ant-popover-inner {
    background-color: $popover-inner-color;
    @include mixins.distant-box-shadow;
  }

  .ant-popover-title {
    border-color: $popover-separator-color;
    color: $popover-title-color;
  }

  .ant-popover-inner-content {
    color: $popover-text-color;
    background-color: $popover-inner-color;
  }

  .ant-popover-arrow-content::before, .ant-popover-arrow-content {
    background-color: $popover-background-color;
  }
}