@use "../palette";

:root {
  --color-primary-light-4: #{palette.$blue-1};
  --color-primary-light-3: #{palette.$blue-2};
  --color-primary-light-2: #{palette.$blue-3};
  --color-primary-light-1: #{palette.$blue-4};
  --color-primary: #{palette.$blue-5};
  --color-primary-dark-1: #{palette.$blue-6};
  --color-primary-dark-2: #{palette.$blue-7};
  --color-primary-dark-3: #{palette.$blue-8};
  --color-primary-dark-4: #{palette.$blue-9};

  --color-danger-light-4: #{palette.$red-1};
  --color-danger-light-3: #{palette.$red-2};
  --color-danger-light-2: #{palette.$red-3};
  --color-danger-light-1: #{palette.$red-4};
  --color-danger: #{palette.$red-5};
  --color-danger-dark-1: #{palette.$red-6};
  --color-danger-dark-2: #{palette.$red-7};
  --color-danger-dark-3: #{palette.$red-8};
  --color-danger-dark-4: #{palette.$red-9};

  --color-success-light-4: #{palette.$green-1};
  --color-success-light-3: #{palette.$green-2};
  --color-success-light-2: #{palette.$green-3};
  --color-success-light-1: #{palette.$green-4};
  --color-success: #{palette.$green-5};
  --color-success-dark-1: #{palette.$green-6};
  --color-success-dark-2: #{palette.$green-7};
  --color-success-dark-3: #{palette.$green-8};
  --color-success-dark-4: #{palette.$green-9};

  --color-warning-light-4: #{palette.$orange-1};
  --color-warning-light-3: #{palette.$orange-2};
  --color-warning-light-2: #{palette.$orange-3};
  --color-warning-light-1: #{palette.$orange-4};
  --color-warning: #{palette.$orange-5};
  --color-warning-dark-1: #{palette.$orange-6};
  --color-warning-dark-2: #{palette.$orange-7};
  --color-warning-dark-3: #{palette.$orange-8};
  --color-warning-dark-4: #{palette.$orange-9};

  --color-secondary-light-4: #{palette.$gray-1};
  --color-secondary-light-3: #{palette.$gray-2};
  --color-secondary-light-2: #{palette.$gray-3};
  --color-secondary-light-1: #{palette.$gray-4};
  --color-secondary: #{palette.$gray-5};
  --color-secondary-dark-1: #{palette.$gray-6};
  --color-secondary-dark-2: #{palette.$gray-7};
  --color-secondary-dark-3: #{palette.$gray-8};
  --color-secondary-dark-4: #{palette.$gray-9};

  --color-main-disabled: #{palette.$gray-6};
  --color-additional-disabled: #{palette.$gray-4};

  --color-text: #{palette.$black};
  --color-text-secondary: var(--color-secondary-dark-1);

  --color-border: var(--color-secondary-light-2);

  --color-white: #{palette.$white};
  --color-black: #{palette.$black};

  --color--mark: #{palette.$yellow-4};
}