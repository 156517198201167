.icon-wrapper {
  position: absolute;
  border-radius: 50%;

  &:hover {
    background-color: var(--color-secondary-light-3);
  }

  &_locked {
    bottom: 0.1rem;
    right: 0.1rem;
    padding: 0.5rem;
  }

  &_unlocked {
    bottom: 0;
    right: 0;
    padding: 0.4rem;
  }
}

.icon {
  display: block;

  &_locked {
    width: 1rem !important;
    height: 1.2rem !important;
  }

  &_unlocked {
    width: 1.2rem !important;
    height: 1.4rem !important;
  }
}