@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/mixins";

$menu-bg-color: var(--color-white);
$menu-text-color: var(--color-text);
$menu-sub-bg-color: $menu-bg-color;

.ant-menu {
  background: $menu-bg-color;
  color: $menu-text-color;
  @include mixins.font-size-14;
}

.ant-menu-submenu > .ant-menu {
  background: $menu-sub-bg-color;
}

.ant-menu-submenu-popup > .ant-menu {
  background: $menu-sub-bg-color;
}