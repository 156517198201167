.sidebar {
  .level1 span {
    margin-left: 0 !important;
  }

  .level1 div:global(.ant-typography) span {
    margin-left: 0 !important;
  }

  .level2 span {
    margin-left: 2rem !important;
  }

  a.level2 div:global(.ant-typography) span {
    margin-left: 2rem !important;
  }

  .level3 span {
    margin-left: 4rem !important;
  }

  a.level3 div:global(.ant-typography) span {
    margin-left: 4rem !important;
  }
}