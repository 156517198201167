@use "./mixins.module";
@use "./constants.module";

.widget-cell {
  @include mixins.cell-contour(constants.$color-widget);

  > div {
    color: constants.$color-widget;
  }

  &_success {
    @include mixins.cell-contour(constants.$color-success);

    > div {
      color: constants.$color-success;
    }
  }

  &_warning {
    @include mixins.cell-contour(constants.$color-warning);

    > div {
      color: constants.$color-warning;
    }
  }

  &_error {
    @include mixins.cell-contour(constants.$color-error);

    > div {
      color: constants.$color-error;
    }
  }
}