.icon-wrapper {
  border-radius: 50%;

  &:hover {
    background-color: var(--color-secondary-light-3);
    cursor: pointer;
  }

  &_disabled {
    &:hover {
      background-color: inherit;
      cursor: default;
    }
  }

  &_locked {
    padding: 0.4rem;
  }

  &_unlocked {
    padding: 0.2rem;
  }
}

.icon {
  display: block;

  &_disabled {
    path[mask], circle, rect {
      fill: var(--color-secondary-light-1);
    }
  }

  &_locked {
    width: 1.333rem !important;
    height: 1.6rem !important;
  }

  &_unlocked {
    width: 1.6rem !important;
    height: 1.866rem !important;
  }
}