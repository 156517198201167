@use "src/shared/UI/styles/functions";
@use "src/shared/UI/styles/variables";

$dropdown-menu-submenu-arrow-color: var(--color-text);
$dropdown-menu-submenu-arrow-hover-color: var(--color-primary);
$dropdown-menu-submenu-arrow-disabled-color: var(--color-text-secondary);

.ant-dropdown-menu-submenu-arrow-icon {
  display: none;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  top: 50%;
  right: 1.6rem;
  width: 1rem;
  transform: translateY(-50%);
  transition: functions.transition(transform);
  color: $dropdown-menu-submenu-arrow-color;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon:after,
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon:before,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon:after,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon:before, {
  content: "";
  position: absolute;
  width: 0.6rem;
  height: 0.15rem;
  background-color: currentcolor;
  border-radius: variables.$border-radius-sm;
  transition: functions.transition(all);
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon:before,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon:before, {
  transform: rotate(45deg) translateY(-0.25rem);
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon:after,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon:after {
  transform: rotate(-45deg) translateY(0.25rem);
}

.ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon {
  color: $dropdown-menu-submenu-arrow-hover-color;
}

.ant-dropdown-menu-item-disabled .ant-dropdown-menu-submenu-expand-icon:before,
.ant-dropdown-menu-item-disabled .ant-dropdown-menu-submenu-expand-icon:after,
.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-expand-icon:before,
.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-expand-icon:after {
  color: $dropdown-menu-submenu-arrow-disabled-color;
}