$icon-button-small-size: 3.2rem;
$icon-button-small-icon-size: 1.2rem;
$icon-button-middle-size: 4rem;
$icon-button-middle-icon-size: 1.8rem;
$icon-button-large-size: 4.8rem;
$icon-button-large-icon-size: 1.8rem;
$icon-button-default-size: "middle";

$icon-button-sizes: (
  "small": (
    "width": $icon-button-small-size,
    "height": $icon-button-small-size,
    "icon-size": $icon-button-small-icon-size,
  ),
  "middle": (
    "width": $icon-button-middle-size,
    "height": $icon-button-middle-size,
    "icon-size": $icon-button-middle-icon-size,
  ),
  "large": (
    "width": $icon-button-large-size,
    "height": $icon-button-large-size,
    "icon-size": $icon-button-large-icon-size,
  ),
);

@mixin setIconButtonSize($sizeValues) {
  width: map-get($sizeValues, "width");
  height: map-get($sizeValues, "height");
  padding: 0;
  
  :global(.app-button-icon-container) {
    svg {
      width: map-get($sizeValues, "icon-size");
      height: map-get($sizeValues, "icon-size");
    }
  }
}

.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  &.borderless {
    border: 0;
  }
  
  $defaultSizeValues: map-get($icon-button-sizes, $icon-button-default-size);
  $sizesWithoutDefault: map-remove($icon-button-sizes, $icon-button-default-size);
  @include setIconButtonSize($defaultSizeValues);
  
  @each $size, $sizeValues in $sizesWithoutDefault {
    &.#{$size} {
      @include setIconButtonSize($sizeValues);
    }
  }
}