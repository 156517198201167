@use "src/shared/UI/styles/variables";
@use "src/shared/UI/styles/global/button/default";
@import "suneditor/dist/css/suneditor.min.css";

$background-color: var(--color-secondary-light-3);

.sun-editor {
  line-height: initial !important;
  border-radius: variables.$border-radius-md;
  height: 100%;
  user-select: all;
  border: none;

  & * {
    font-family: variables.$secondary-font-family !important;
  }

  & span[data-widget] {
    display: inline-block;
    text-indent: 0 !important;
    line-height: normal;

    &[data-type="placeholder"] {
      display: inline;
      white-space: pre-line;
    }

    &.placeholder-line-break {
      display: inline-block;
    }
  }

  & p, h1, h2, h3, h4, h5, h6, blockquote {
    margin: 0 !important;
  }

  & ul {
    margin: 0;
    padding-left: 1.8cm;
    line-height: normal;
  }

  .se-btn {
    background-color: default.$button-default-bg-color;
    width: auto;
    min-width: 4rem;
  }


  .se-wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .se-wrapper-inner {
    padding: 0;
    height: 100% !important;

    &.se-wrapper-wysiwyg {
      overflow: hidden;
    }
  }

  .se-resizing-bar {
    display: none;
  }

  table td,
  table th {
    border-color: var(--color-black) !important;
    padding: variables.$padding-xs !important;
  }

  table tr,
  table td,
  table th {
    height: auto !important;
  }
}
