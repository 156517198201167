@use "src/shared/UI/styles/variables" as vars;
@use "src/shared/UI/styles/mixins";

.container {
  width: 18rem;
  min-width: 18rem;
  padding: vars.$padding-xs;
  border-radius: vars.$border-radius-sm;
  background-color: var(--color-white);
  @include mixins.distant-box-shadow();
}